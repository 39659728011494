<template lang="">
  <b-modal
    id="modal-send-incorrect-invoice-emails"
    size="xl"
    centered
    header-bg-variant="light-primary"
    no-close-on-backdrop
    no-close-on-esc
    body-class="p-1"
    title-class="h3 font-weight-bolder text-dark"
    :title="$t('invoice.incorrectInvoices.sendEmailModal.title')"
    hide-footer
    @show="onShowHandle"
  >
    <ValidationObserver ref="refFormObserver">
      <Stack
        v-if="incorrectInvoices?.length"
        id="div-table"
        class="gap-2"
      >
        <h4 class="mb-0">
          {{ $t('invoice.incorrectInvoices.sendEmailModal.headerTable') }}
        </h4>
        <div
          v-for="(incorrectInvoice, incorrectInvoiceId) in incorrectInvoices"
          :key="incorrectInvoiceId"
        >
          <b-table
            ref="refIncorrectInvoiceListTable"
            style="max-height: 70vh"
            :sticky-header="true"
            class="position-relative"
            :items="incorrectInvoice?.invoiceIncorrectItems ?? []"
            responsive
            primary-key="id"
            show-empty
            :empty-text="$t('noMatchingResult')"
            no-border-collapse
            small
            bordered
            :fields="tableColumnSendEmails"
          >
            <template
              v-for="column in tableColumnSendEmails"
              #[`head(${column.key})`]="data"
            >
              <HStack
                :key="column.key"
                center
                class="text-wrap text-center"
              >
                <div>{{ $t(`invoice.incorrectInvoices.sendEmailModal.columns.${data.column}`) }}</div>
                <span
                  v-if="['email', 'recipientName'].includes(data.column)"
                  class="text-danger"
                >(*)</span>
              </HStack>
            </template>

            <!-- ANCHOR STT -->
            <template #cell(no)="row">
              <div class="text-right">
                {{ row.index + 1 }}
              </div>
            </template>

            <!-- ANCHOR Mẫu số/ ký hiệu -->
            <template #cell(templateNumberAndSymbol)="{ item }">
              <ValidationProvider
                #default="{ errors }"
                :name="$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')"
                rules="required"
                :vid="`templateNumberAndSymbol-${item.id}`"
              >
                <BFormGroup :class="{'is-invalid': errors[0]}">
                  <BFormInput
                    :id="`templateNumberAndSymbol-${item.id}`"
                    :value="item.templateNumberAndSymbol"
                    style="overflow: auto"
                    :name="$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')"
                    :state="errors[0] ? false : null"
                    disabled
                    :placeholder="$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')"
                    :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                  />
                  <b-tooltip
                    v-if="errors[0]"
                    variant="danger"
                    boundary="window"
                    :target="`templateNumberAndSymbol-${item.id}`"
                    triggers="hover"
                  >
                    {{ errors[0] }}
                  </b-tooltip>
                </BFormGroup>
              </ValidationProvider>
            </template>

            <!-- ANCHOR Số hoá đơn  -->
            <template #cell(invoiceNumber)="{ item }">
              <div class="width-100">
                <ValidationProvider
                  #default="{ errors }"
                  :name="$t('invoice.incorrectInvoices.columns.invNumber')"
                  :vid="`invNumber-${item.id}`"
                  :rules="`required`"
                >
                  <BFormGroup :class="{'is-invalid': errors[0]}">
                    <BFormInput
                      :id="`invNumber-${item.id}`"
                      :value="item.invoiceNumber"
                      style="overflow: auto"
                      disabled
                      :name="$t('invoice.incorrectInvoices.columns.invNumber')"
                      :state="errors[0] ? false : null"
                      :placeholder="$t('invoice.incorrectInvoices.columns.invNumber')"
                      :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                    />
                    <b-tooltip
                      v-if="errors[0]"
                      variant="danger"
                      boundary="window"
                      :target="`invNumber-${item.id}`"
                      triggers="hover"
                    >
                      {{ errors[0] }}
                    </b-tooltip>
                  </BFormGroup>
                </ValidationProvider>
              </div>
            </template>

            <!-- ANCHOR Loại hoá đơn  -->
            <template #cell(invoiceType)="{ item }">
              <BFormGroup>
                <BFormInput
                  :id="`templateNumberAndSymbol-${item.id}`"
                  :value="item.isInternal ? 'Trong hệ thống' : 'Ngoài hệ thống'"
                  style="overflow: auto"
                  disabled
                  class="fw-700 border-transparent"
                />
              </BFormGroup>
            </template>

            <!-- ANCHOR Ngày lập hoá đơn -->
            <template #cell(invoiceDate)="{ item }">
              <div class="width-100">
                <ValidationProvider
                  #default="{ errors }"
                  :name="$t('invoice.incorrectInvoices.columns.invoiceDate')"
                  rules="required"
                  :vid="`invoiceDate-${item.id}`"
                >
                  <BFormGroup
                    :id="`form-group-invoiceDate-${item.id}`"
                    label-for="dateOfIssuance"
                  >
                    <BFormInput
                      :value="item.invoiceDate"
                      class="d-none"
                    />
                    <div :class="`form-control ${errors[0] ? 'is-invalid' : 'border-transparent'} p-0`">
                      <flat-pickr
                        :id="`invoiceDate-${item.id}`"
                        :value="convertISODateTime(item.invoiceDate).dateFilter"
                        disabled
                        :name="$t('invoice.incorrectInvoices.columns.invoiceDate')"
                        :placeholder="`${$t('invoice.incorrectInvoices.columns.invoiceDate')}`"
                        :config="{
                          allowInput: true,
                          dateFormat: 'Y-m-d',
                          altFormat: 'd/m/Y',
                          altInput: true,
                          maxDate: today,
                          locale: $i18n.locale === 'vi' ? Vietnamese : null,
                          disableMobile: true,
                          altInputClass: 'form-control input px-50 py-0 border-transparent height-35px',
                        }"
                      />
                    </div>
                    <b-tooltip
                      v-if="errors[0]"
                      variant="danger"
                      boundary="window"
                      :target="`form-group-invoiceDate-${item.id}`"
                      triggers="hover"
                    >
                      {{ errors[0] }}
                    </b-tooltip>
                  </BFormGroup>
                </ValidationProvider>
              </div>
            </template>

            <!-- ANCHOR Loại hoá đơn  -->
            <template #cell(invoiceBuyerUnitName)="{ item }">
              <BFormGroup>
                <BFormInput
                  :value="item.invoiceBuyerUnitName"
                  style="overflow: auto"
                  disabled
                  class="fw-700 border-transparent"
                />
              </BFormGroup>
            </template>

            <!-- ANCHOR Tổng tiền thanh toán -->
            <template #cell(amount)="{ item }">
              <div class="">
                <ValidationProvider
                  #default="{ errors }"
                  :name="$t('invoice.incorrectInvoices.columns.amount')"
                  rules="required|min_value:0"
                >
                  <BFormGroup :class="{'is-invalid': errors[0]}">
                    <BFormInput
                      :value="item.amount"
                      class="d-none"
                      disabled
                      :name="$t('invoice.incorrectInvoices.columns.amount')"
                    />
                    <IAmInputMoney
                      :id="`amount-${item.id}`"
                      :value-money.sync="item.amount"
                      :canInputNegative="true"
                      disabled
                      :custom-class="`${!errors[0] ? 'border-transparent' : ''}`"
                      :state="errors[0] ? false : null"
                      :name="$t('invoice.incorrectInvoices.columns.amount')"
                    />
                    <b-tooltip
                      v-if="errors[0]"
                      variant="danger"
                      boundary="window"
                      :target="`amount-${item.id}`"
                      triggers="hover"
                    >
                      {{ errors[0] }}
                    </b-tooltip>
                  </BFormGroup>
                </ValidationProvider>
              </div>
            </template>

            <!-- ANCHOR Lý do -->
            <template #cell(reason)="{ item }">
              <div class="">
                <ValidationProvider
                  #default="{ errors }"
                  :name="$t('invoice.incorrectInvoices.columns.reason')"
                  :vid="`reason-${item.id}`"
                  rules="required"
                >
                  <BFormInput
                    :value="item.reason"
                    class="d-none"
                  />
                  <BFormGroup :class="{'is-invalid': errors[0]}">
                    <IAmInputTextOrTextarea
                      :id="`reason-${item.id}`"
                      :text.sync="item.reason"
                      :state="errors[0] ? false : null"
                      type="text"
                      :name="$t('invoice.incorrectInvoices.columns.reason')"
                      :class="{
                        'border-transparent': !errors[0],
                      }"
                      disabled
                      rows="1"
                      max-rows="4"
                      :placeholder="$t('invoice.incorrectInvoices.ph.reason')"
                    />
                    <b-tooltip
                      v-if="errors[0]"
                      variant="danger"
                      boundary="window"
                      :target="`reason-${item.id}`"
                      triggers="hover"
                    >
                      {{ errors[0] }}
                    </b-tooltip>
                  </BFormGroup>
                </ValidationProvider>
              </div>
            </template>

            <!-- ANCHOR Trạng thái TBSS  -->
            <template #cell(incorrectInvoiceStatus)>
              <HStack center>
                <BBadge
                  :variant="resolveNotificationStatusVariant(incorrectInvoice?.status)"
                  pill
                  class="px-75 fit-content"
                >
                  {{ $t(`invoice.incorrectInvoices.notificationStatus.${incorrectInvoice?.status}`) }}
                </BBadge>
              </HStack>
            </template>

            <!-- ANCHOR Tên người nhận -->
            <template #cell(recipientName)="{ item }">
              <ValidationProvider
                #default="{ errors }"
                :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.recipientName')"
                rules="required"
                :vid="`recipientName-${item.id}`"
              >
                <BFormGroup :class="{'is-invalid': errors[0]}">
                  <BFormInput
                    :id="`recipientName-${item.id}`"
                    v-model="item.invoiceBuyerName"
                    style="overflow: auto"
                    :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.recipientName')"
                    :state="errors[0] ? false : null"
                    :placeholder="$t('invoice.incorrectInvoices.sendEmailModal.columns.recipientName')"
                    :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                  />
                  <b-tooltip
                    v-if="errors[0]"
                    variant="danger"
                    boundary="window"
                    :target="`recipientName-${item.id}`"
                    triggers="hover"
                  >
                    {{ errors[0] }}
                  </b-tooltip>
                </BFormGroup>
              </ValidationProvider>
            </template>
            <!-- ANCHOR email -->
            <template #cell(email)="{ item }">
              <ValidationProvider
                #default="{ errors }"
                :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.email')"
                rules="required|email"
                :vid="`email-${item.id}`"
              >
                <BFormGroup :class="{'is-invalid': errors[0]}">
                  <BFormInput
                    :id="`email-${item.id}`"
                    v-model="item.email"
                    style="overflow: auto"
                    :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.email')"
                    :state="errors[0] ? false : null"
                    :placeholder="$t('invoice.incorrectInvoices.sendEmailModal.columns.email')"
                    :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                  />
                  <b-tooltip
                    v-if="errors[0]"
                    variant="danger"
                    boundary="window"
                    :target="`email-${item.id}`"
                    triggers="hover"
                  >
                    {{ errors[0] }}
                  </b-tooltip>
                </BFormGroup>
              </ValidationProvider>
            </template>

            <!-- ANCHOR cc -->
            <template #cell(cc)="{ item }">
              <ValidationProvider
                #default="{ errors }"
                :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.cc')"
                rules="email"
                :vid="`cc-${item.id}`"
              >
                <BFormGroup :class="{'is-invalid': errors[0]}">
                  <BFormInput
                    :id="`cc-${item.id}`"
                    v-model="item.cc"
                    style="overflow: auto"
                    :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.cc')"
                    :state="errors[0] ? false : null"
                    :placeholder="$t('invoice.incorrectInvoices.sendEmailModal.columns.cc')"
                    :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                  />
                  <b-tooltip
                    v-if="errors[0]"
                    variant="danger"
                    boundary="window"
                    :target="`cc-${item.id}`"
                    triggers="hover"
                  >
                    {{ errors[0] }}
                  </b-tooltip>
                </BFormGroup>
              </ValidationProvider>
            </template>

            <!-- ANCHOR Tên người nhận -->
            <template #cell(bcc)="{ item }">
              <ValidationProvider
                #default="{ errors }"
                :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.bcc')"
                rules="bcc"
                :vid="`bcc-${item.id}`"
              >
                <BFormGroup :class="{'is-invalid': errors[0]}">
                  <BFormInput
                    :id="`bcc-${item.id}`"
                    v-model="item.bcc"
                    style="overflow: auto"
                    :name="$t('invoice.incorrectInvoices.sendEmailModal.columns.bcc')"
                    :state="errors[0] ? false : null"
                    :placeholder="$t('invoice.incorrectInvoices.sendEmailModal.columns.bcc')"
                    :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                  />
                  <b-tooltip
                    v-if="errors[0]"
                    variant="danger"
                    boundary="window"
                    :target="`bcc-${item.id}`"
                    triggers="hover"
                  >
                    {{ errors[0] }}
                  </b-tooltip>
                </BFormGroup>
              </ValidationProvider>
            </template>
          </b-table>
        </div>
        <div>
          <BButton
            disabled
            size="sm"
            class="px-50"
            variant="outline-primary"
          >
            <HStack center>
              <IAmIcon
                icon="newFileOutline"
                size="18"
              />
              {{ $t('invoice.incorrectInvoices.sendEmailModal.attachNotiDocuments') }}
            </HStack>
          </BButton>
        </div>

        <BFormCheckbox v-model="isAttachErrorAndReceipt">
          <span class="fw-700 font-medium-2">
            {{ $t('invoice.incorrectInvoices.sendEmailModal.attachErrorAndReceipt') }}
          </span>
        </BFormCheckbox>
        <div
          v-if="isAttachErrorAndReceipt"
          class="fw-700"
        >
          <div
            v-for="(incorrectInvoice, incorrectInvoiceId) in incorrectInvoices"
            :key="incorrectInvoiceId"
          >
            {{ incorrectInvoice.notificationNumber }}
          </div>
        </div>
      </Stack>

      <!-- ANCHOR footer button -->
      <HStack
        center
        class="mt-1 gap-3"
      >
        <BButton
          class="width-150"
          variant="secondary"
          @click="onCloseHandle"
        >
          {{ $t('close') }}
        </BButton>
        <BButton
          class="width-150"
          variant="primary"
          @click="onSendMailsHandle"
        >
          {{ $t('submit') }}
        </BButton>
      </HStack>
    </ValidationObserver>
  </b-modal>
</template>
<script>
import {
  BBadge,
  BButton, BFormGroup, BFormInput, BModal, BTable,
  BTooltip,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import flatPickr from 'vue-flatpickr-component'
import { ref, toRefs } from '@vue/composition-api'

import formValidation from '@/@core/comp-functions/forms/form-validation'
import { convertISODateTime, getToday } from '@/@core/utils/filter'

import { resolveNotificationStatusVariant, useSendIncorrectInvoiceEmailsHandle } from '../useIncorrectInvoiceHandle'

export default {
  components: {
    BModal,
    BButton,
    BTable,
    BTooltip,
    BFormInput,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BBadge,
    BFormCheckbox,
    flatPickr,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmInputTextOrTextarea: () => import('@/components/IAmInputTextOrTextarea.vue'),
  },
  props: {
    incorrectInvoices: {
      type: Array,
      required: true,
    },
  },
  setup(props, { root }) {
    const { refFormObserver } = formValidation()
    const { sendIncorrectInvoiceEmails } = useSendIncorrectInvoiceEmailsHandle()
    const { incorrectInvoices } = toRefs(props)
    const isAttachErrorAndReceipt = ref(false)
    const tableColumnSendEmails = [
      { key: 'no' }, // STT
      { key: 'templateNumberAndSymbol', thClass: 'width-80' }, // Mẫu số ký hiệu
      { key: 'invoiceNumber' }, // Số hoá đơn
      { key: 'invoiceType' }, // isInternal // Loại Hoá đơn
      { key: 'invoiceDate', thClass: 'width-80' }, // Ngày tạo hoá đơn
      { key: 'invoiceBuyerUnitName' }, // Tên khách hàng
      { key: 'amount', thClass: 'width-120' }, // Tổng tiền thanh toán
      { key: 'reason' }, // Lý do
      { key: 'incorrectInvoiceStatus', thClass: 'width-100' }, // Trạng thái thông báo sai sót
      { key: 'recipientName' }, // Tên người nhận
      { key: 'email' }, // Email
      { key: 'cc' }, // CC
      { key: 'bcc' }, // BCC
    ]
    function onShowHandle() {}
    function onCloseHandle() {
      root.$bvModal.hide('modal-send-incorrect-invoice-emails')
    }
    async function onSendMailsHandle() {
      const isValid = await refFormObserver.value.validate()
      if (!isValid) return

      const payload = {
        mails: incorrectInvoices.value.map(incorrectInvoice => ({
          iinId: incorrectInvoice?.externalId,
          attachErrorAndReceipt: isAttachErrorAndReceipt.value,
          sendMailDetails: incorrectInvoice.invoiceIncorrectItems.map(invoiceIncorrectItem => ({
            recipientName: invoiceIncorrectItem?.invoiceBuyerName && `${invoiceIncorrectItem?.invoiceBuyerName}`,
            email: invoiceIncorrectItem?.email,
            ccList: invoiceIncorrectItem?.cc ? [invoiceIncorrectItem?.cc] : [],
            bccList: invoiceIncorrectItem?.bcc ? [invoiceIncorrectItem?.bcc] : [],
            attachmentList: [],
            iinDetailId: invoiceIncorrectItem?.externalId,
          })),
        })),
      }
      await sendIncorrectInvoiceEmails(payload)
      onCloseHandle()
    }
    return {
      onShowHandle,
      onCloseHandle,
      onSendMailsHandle,
      tableColumnSendEmails,
      refFormObserver,
      Vietnamese,
      today: getToday('YYYY-MM-DD'),
      convertISODateTime,
      resolveNotificationStatusVariant,
      isAttachErrorAndReceipt,
    }
  },
}
</script>
<style lang="scss" scoped>
#div-table::v-deep {
  .table.table-sm th, .table.table-sm td {
    padding: 0.3rem 0.2rem;
  }
  .form-control {
    padding: 0.3rem;
  }
  .width-120 {
    width: 120px;
  }
  .width-80 {
    width: 80px;
  }
  .width-100 {
    width: 100px;
  }
  .form-group {
    margin-bottom: 0.25rem;
  }
  .table {
    thead th {
      vertical-align: middle;
    }
  }
  .height-35px {
    height: 35px;
  }
}
</style>
