var render = function () {
  var _ref2, _vm$invoiceData$compa, _vm$invoiceData, _vm$invoiceData3, _vm$invoiceData$statu, _vm$invoiceData4, _vm$invoiceData5, _vm$invoiceData6;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "id": "invoice-create-info-card",
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "usersOutline",
      "size": "25"
    },
    nativeOn: {
      "dblclick": function dblclick($event) {
        $event.preventDefault();
        return _vm.$emit('quick-input');
      }
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$tc('invoice.create.info.title', _vm.isInvoiceCreateAndRequestRelease ? 3 : _vm.isInvoiceRequest ? 2 : 1)) + " ")])], 1), _c('b-row', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.sellerInfo'),
      "rules": "required",
      "vid": "sellerInfo"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var errors = _ref.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "sellerInfo"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.sellerInfo')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "w-100",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "sellerInfo",
            "value": _vm.invoiceData.company,
            "name": _vm.$t('invoice.create.info.sellerInfo'),
            "options": _vm.companyOptions,
            "label": "name",
            "disabled": !_vm.isEditing,
            "clearable": false,
            "placeholder": _vm.$t('placeholderSelect')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                company: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.name) + " ")];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.name) + " ")];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "sellerInfo",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.symbol')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.invoiceData.symbol,
      "placeholder": _vm.$t('invoice.create.info.phSymbol'),
      "formatter": _vm.onlyUpperCaseFormatter,
      "disabled": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          symbol: val
        }));
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.series')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.invoiceData.templateNumber,
      "formatter": _vm.onlyUpperCaseFormatter,
      "placeholder": _vm.$t('invoice.create.info.phSeries'),
      "disabled": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          templateNumber: val
        }));
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.invNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": _vm.invoiceData.invNumber,
      "disabled": "",
      "placeholder": "".concat((_ref2 = (_vm$invoiceData$compa = _vm.invoiceData.company) !== null && _vm$invoiceData$compa !== void 0 && _vm$invoiceData$compa.generateInvoiceNumberOnCreation ? "".concat(_vm.$t('agency.company.field.generateInvoiceNumberOnCreation'), " ").concat(_vm.$t('agency.company.field.onCreation')) : "".concat(_vm.$t('agency.company.field.generateInvoiceNumberOnCreation'), " ").concat(_vm.$t('agency.company.field.onRelease'))) !== null && _ref2 !== void 0 ? _ref2 : _vm.$t('invoice.create.info.invNumber')),
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          invNumber: val
        }));
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.taxAuthorityCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": (_vm$invoiceData = _vm.invoiceData) === null || _vm$invoiceData === void 0 ? void 0 : _vm$invoiceData.releasedInvCode,
      "disabled": "",
      "placeholder": _vm.$t('invoice.create.info.taxAuthorityCode'),
      "formatter": _vm.onlyUpperCaseFormatter
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.invoiceType'),
      "rules": "required",
      "vid": "invoiceType"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref3) {
        var _vm$invoiceData2;
        var errors = _ref3.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "invoiceType"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.invoiceType')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "invoiceType",
            "value": (_vm$invoiceData2 = _vm.invoiceData) === null || _vm$invoiceData2 === void 0 ? void 0 : _vm$invoiceData2.invoiceType,
            "name": _vm.$t('invoice.create.info.invoiceType'),
            "options": [],
            "disabled": "",
            "clearable": false,
            "placeholder": _vm.$t('invoice.create.info.phInvoiceType')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                invoiceType: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.label) + " ")];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.label) + " ")];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "invoiceType",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.invoiceTemplate'),
      "rules": "required",
      "vid": "invoiceTemplate"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref4) {
        var errors = _ref4.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "invoiceTemplate"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.invoiceTemplate')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "invoiceTemplate",
            "value": _vm.invoiceData.template,
            "name": _vm.$t('invoice.create.info.invoiceTemplate'),
            "options": _vm.invoiceTemplateOptions,
            "label": "name",
            "disabled": !_vm.isEditing,
            "clearable": false,
            "placeholder": _vm.$t('invoice.create.info.phInvoiceTemplate')
          },
          on: {
            "input": function input(val) {
              return _vm.handleSelectInvoiceTemplate(val);
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.name) + " ")];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_vm._v(" " + _vm._s(data.name) + " ")];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "invoiceTemplate",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.paymentMethod'),
      "rules": "",
      "vid": "paymentMethod"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref5) {
        var errors = _ref5.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "paymentMethod"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.paymentMethod')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "paymentMethod",
            "value": _vm.invoiceData.paymentMethod,
            "options": _vm.PAYMENT_METHOD_LIST,
            "clearable": false,
            "disabled": !_vm.isEditing,
            "placeholder": _vm.$t('invoice.create.info.phPaymentMethod'),
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                paymentMethod: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(_ref6) {
              var label = _ref6.label;
              return [_vm._v(" " + _vm._s(label) + " ")];
            }
          }, {
            key: "selected-option",
            fn: function fn(_ref7) {
              var label = _ref7.label;
              return [_vm._v(" " + _vm._s(label) + " ")];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "paymentMethod",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.create.info.createdAt')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "value": _vm.invoiceData.createdAt,
      "placeholder": "".concat(_vm.$t('invoice.create.info.createdAt')),
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      },
      "disabled": ""
    },
    on: {
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          createdAt: val
        }));
      }
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.lookUpCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": (_vm$invoiceData3 = _vm.invoiceData) === null || _vm$invoiceData3 === void 0 ? void 0 : _vm$invoiceData3.referenceCode,
      "disabled": "",
      "placeholder": _vm.$t('invoice.create.info.lookUpCode'),
      "formatter": _vm.onlyUpperCaseFormatter
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.transactionCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "value": null,
      "disabled": "",
      "placeholder": _vm.$t('invoice.create.info.transactionCode'),
      "formatter": _vm.onlyUpperCaseFormatter
    }
  })], 1)], 1)], 1), _c('b-row', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.invoiceStatus')
    }
  }, [_c('v-select', {
    staticClass: "w-100 ",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": (_vm$invoiceData$statu = (_vm$invoiceData4 = _vm.invoiceData) === null || _vm$invoiceData4 === void 0 ? void 0 : _vm$invoiceData4.status) !== null && _vm$invoiceData$statu !== void 0 ? _vm$invoiceData$statu : (_vm$invoiceData5 = _vm.invoiceData) === null || _vm$invoiceData5 === void 0 ? void 0 : _vm$invoiceData5.invoiceStatus,
      "options": _vm.INVOICE_STATUS_OPTIONS,
      "label": "label",
      "disabled": "",
      "clearable": false,
      "placeholder": _vm.$t('invoice.create.info.invoiceStatus'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceStatus.".concat(data.label))) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceStatus.".concat(data.label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }])
  })], 1)], 1), _vm.isAdjust || (_vm$invoiceData6 = _vm.invoiceData) !== null && _vm$invoiceData6 !== void 0 && _vm$invoiceData6.referenceAdjustedInvoice ? _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.detail.adjustmentType'),
      "rules": "required",
      "vid": "adjustmentType"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref8) {
        var errors = _ref8.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "adjustmentType"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.detail.adjustmentType')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "adjustmentType",
            "value": _vm.invoiceData.adjustmentType,
            "name": _vm.$t('invoice.create.detail.adjustmentType'),
            "options": _vm.ADJUSTMENT_TYPE_OPTIONS,
            "label": "label",
            "disabled": !_vm.isEditing,
            "clearable": false,
            "reduce": function reduce(val) {
              return val.label;
            },
            "placeholder": _vm.$t('invoice.create.detail.phAdjustmentType')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                adjustmentType: val
              }));
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("invoice.ADJUSTMENT_TYPE.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("invoice.ADJUSTMENT_TYPE.".concat(data.label))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "adjustmentType",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }], null, false, 1657939733)
  })], 1) : _vm._e(), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('invoice.create.info.assignTag')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.invoiceData.tags,
      "options": _vm.tagOptions,
      "taggable": "",
      "multiple": "",
      "push-tags": "",
      "label": "label",
      "clearable": "",
      "disabled": !_vm.isEditing,
      "placeholder": _vm.$t('invoice.create.info.phAssignTag'),
      "reduce": function reduce(val) {
        return val;
      },
      "loading": _vm.loadingTags
    },
    on: {
      "open": _vm.onOpenTagHandle,
      "input": function input(val) {
        return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
          tags: val
        }));
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }, {
      key: "spinner",
      fn: function fn(_ref9) {
        var loading = _ref9.loading;
        return [loading ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1)], 1), _c('div', {
    staticClass: "px-75 mt-50"
  }, [_c('h5', {
    staticClass: "fw-700 mb-0",
    staticStyle: {
      "margin-left": "-0.75rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.buyerInfo')) + ": ")]), _c('b-row', {
    staticClass: "px-25 py-50 border-secondary rounded-lg bg-gray"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.agency'),
      "rules": "required",
      "vid": "agency"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref10) {
        var _vm$invoiceData7;
        var errors = _ref10.errors;
        return [_c('b-form-group', {
          class: {
            'is-invalid': errors[0]
          },
          attrs: {
            "label-for": "agency"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.agency')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('v-select', {
          staticClass: "w-100 bg-white",
          staticStyle: {
            "font-size": "1rem"
          },
          attrs: {
            "id": "agency",
            "value": (_vm$invoiceData7 = _vm.invoiceData) === null || _vm$invoiceData7 === void 0 ? void 0 : _vm$invoiceData7.agency,
            "name": _vm.$t('invoice.create.info.agency'),
            "options": _vm.agencyOptions,
            "reduce": function reduce(val) {
              return val;
            },
            "append-to-body": "",
            "calculate-position": _vm.withPopper,
            "label": "agencyCode",
            "filterable": "",
            "disabled": !_vm.isEditing,
            "clearable": true,
            "placeholder": _vm.$t('invoice.create.info.agency'),
            "loading": _vm.loadingAgency
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                agency: val,
                buyerEmail: val === null || val === void 0 ? void 0 : val.emailAddress
              }));
            },
            "open": _vm.onOpenAgencySelect
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "d-block font-weight-bold text-truncate text-uppercase"
              }, [_c('span', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(data.agencyName) + ")")])])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', {
                staticClass: "d-block font-weight-bold text-truncate text-uppercase"
              }, [_c('span', {
                staticClass: "font-weight-bolder"
              }, [_vm._v(_vm._s(data === null || data === void 0 ? void 0 : data.agencyCode))])])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
            },
            proxy: true
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "agency",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.buyerName'),
      "rules": ""
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref11) {
        var errors = _ref11.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "buyerName"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.buyerName')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('vue-autosuggest', {
          attrs: {
            "id": "buyerName",
            "value": _vm.invoiceData.buyerName,
            "name": _vm.$t('invoice.create.info.buyerName'),
            "suggestions": _vm.buyerOptions,
            "input-props": {
              placeholder: "".concat(_vm.$t('invoice.create.info.phBuyerName')),
              state: "".concat(errors[0] ? false : null),
              disabled: !_vm.isEditing,
              class: "form-control text-body px-25 ".concat(errors[0] ? 'is-invalid' : '')
            },
            "section-configs": _vm.sectionConfigs,
            "get-suggestion-value": function getSuggestionValue(suggestion) {
              return _vm.getSuggestionValue(suggestion);
            }
          },
          on: {
            "input": _vm.onInputBuyerNameHandle
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref12) {
              var _suggestion$item, _suggestion$item$comp;
              var suggestion = _ref12.suggestion;
              return [_c('div', {
                staticClass: "cursor-pointer"
              }, [_c('div', {
                staticClass: "fw-600"
              }, [_vm._v(" " + _vm._s(suggestion.item.lastName) + " " + _vm._s(suggestion.item.firstName) + " ")]), _c('small', {
                staticClass: "text-secondary"
              }, [_vm._v(_vm._s((_suggestion$item = suggestion.item) === null || _suggestion$item === void 0 ? void 0 : (_suggestion$item$comp = _suggestion$item.company) === null || _suggestion$item$comp === void 0 ? void 0 : _suggestion$item$comp.name))])])];
            }
          }], null, true)
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "buyerName",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.buyerCompany'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref13) {
        var errors = _ref13.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "buyerCompany"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.buyerCompany')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "buyerCompany",
            "value": _vm.invoiceData.buyerUnitName,
            "name": _vm.$t('invoice.create.info.buyerCompany'),
            "state": errors[0] ? false : null,
            "disabled": !_vm.isEditing,
            "placeholder": _vm.$t('invoice.create.info.phBuyerCompany')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                buyerUnitName: val
              }));
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "buyerCompany",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "2"
    }
  }, [_c('ValidationProvider', {
    attrs: {
      "name": _vm.$t('invoice.create.info.buyerTaxCode'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref14) {
        var errors = _ref14.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "buyerTaxCode"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.buyerTaxCode')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-row', {
          attrs: {
            "align-v": "center"
          }
        }, [_c('b-col', {
          staticClass: "pr-25",
          attrs: {
            "cols": "7"
          }
        }, [_c('b-form-input', {
          attrs: {
            "id": "buyerTaxCode",
            "value": _vm.invoiceData.buyerTaxCode,
            "placeholder": _vm.$t('invoice.create.info.buyerTaxCode'),
            "formatter": _vm.onlyUpperCaseFormatter,
            "disabled": !_vm.isEditing,
            "state": errors[0] ? false : null
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                buyerTaxCode: val
              }));
            }
          }
        })], 1), _c('b-col', {
          staticClass: "pl-0",
          attrs: {
            "cols": "5"
          }
        }, [_c('BButton', {
          staticClass: "p-50 text-nowrap w-100",
          attrs: {
            "variant": "primary",
            "size": "sm",
            "pill": "",
            "disabled": _vm.loadingGetCompanyByTaxCode || !_vm.invoiceData.buyerTaxCode || !_vm.isEditing
          },
          on: {
            "click": function click($event) {
              return _vm.handleFindTaxCode(_vm.invoiceData.buyerTaxCode);
            }
          }
        }, [_vm.loadingGetCompanyByTaxCode ? _c('span', [_c('BSpinner', {
          attrs: {
            "variant": "light",
            "small": ""
          }
        })], 1) : _c('span', {
          staticClass: "text-truncate w-100 d-block"
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.findByTaxCode')) + " ")])])], 1)], 1), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "buyerTaxCode",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-50 d-flex-between gap-2",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('ValidationProvider', {
    staticClass: "flex-1",
    attrs: {
      "name": _vm.$t('invoice.create.info.buyerAddress'),
      "rules": "required"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref15) {
        var errors = _ref15.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "buyerAddress"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.buyerAddress')) + " "), _c('span', {
                staticClass: "text-danger"
              }, [_vm._v("(*)")])];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "buyerAddress",
            "value": _vm.invoiceData.buyerAddress,
            "name": _vm.$t('invoice.create.info.buyerAddress'),
            "state": errors[0] ? false : null,
            "disabled": !_vm.isEditing,
            "placeholder": _vm.$t('invoice.create.info.phBuyerAddress')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                buyerAddress: val
              }));
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "buyerAddress",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  }), _c('ValidationProvider', {
    staticClass: "flex-1",
    attrs: {
      "name": _vm.$t('invoice.create.info.buyerEmail'),
      "rules": "email"
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref16) {
        var errors = _ref16.errors;
        return [_c('b-form-group', {
          attrs: {
            "label-for": "buyerEmail"
          },
          scopedSlots: _vm._u([{
            key: "label",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.buyerEmail')) + " ")];
            },
            proxy: true
          }], null, true)
        }, [_c('b-form-input', {
          attrs: {
            "id": "buyerEmail",
            "value": _vm.invoiceData.buyerEmail,
            "name": _vm.$t('invoice.create.info.buyerEmail'),
            "state": errors[0] ? false : null,
            "disabled": !_vm.isEditing,
            "placeholder": _vm.$t('invoice.create.info.phBuyerEmail')
          },
          on: {
            "input": function input(val) {
              return _vm.$emit('update:invoiceData', Object.assign({}, _vm.invoiceData, {
                buyerEmail: val
              }));
            }
          }
        }), errors[0] ? _c('b-tooltip', {
          attrs: {
            "variant": "danger",
            "boundary": "window",
            "target": "buyerEmail",
            "triggers": "hover"
          }
        }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
      }
    }])
  })], 1), _c('b-col', {
    staticClass: "px-0",
    attrs: {
      "cols": "12",
      "md": "1",
      "align-self": "end"
    }
  }, [_c('BButton', {
    staticClass: "p-50 text-wrap mb-50",
    attrs: {
      "variant": "primary",
      "size": "sm",
      "pill": "",
      "disabled": !_vm.isEditing || !_vm.validSaveBuyerInfo
    },
    on: {
      "click": _vm.handleSaveBuyerInfo
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.info.saveBuyerInfo')) + " ")])], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }