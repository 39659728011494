<template lang="">
  <BCard
    id="incorrect-invoice-info-card"
    body-class="p-50"
    class="mb-0"
  >
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="usersOutline"
        size="25"
        class="p-1 icon-card"
      />
      <!-- @dblclick.native.prevent="$emit('quick-input')" -->
      <span class="font-medium-3 m-0 fw-700">
        {{ $t('invoice.incorrectInvoices.infoCardTitle') }}
      </span>
    </div>

    <b-row class="px-75 my-50">
      <!-- ANCHOR Loại thông báo -->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.notificationType')"
          rules="required"
          vid="notificationType"
        >
          <b-form-group
            label-for="notificationType"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.notificationType') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="notificationType"
              :value="incorrectInvoiceData?.notificationType"
              :name="$t('invoice.incorrectInvoices.columns.notificationType')"
              class="w-100 vue-select-class"
              style="font-size: 1rem"
              :options="INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS"
              label="label"
              :reduce="val => val.value"
              :disabled="!isEditing"
              :clearable="false"
              :placeholder="$t('placeholderSelect')"
              @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, notificationType: val })"
            >
              <template #option="data">
                <BBadge
                  :variant="data.variant"
                  rounded
                  class="px-75 text-wrap text-left"
                >
                  {{ $t(`invoice.incorrectInvoices.notificationType.${data.label}`) }}
                </BBadge>
              </template>

              <template #selected-option="data">
                <BBadge
                  :variant="data.variant"
                  rounded
                  class="px-75"
                >
                  {{ $t(`invoice.incorrectInvoices.notificationType.${data.label}`) }}
                </BBadge>
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationType"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Số thông báo -->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.notificationNumber')"
          rules=""
        >
          <b-form-group label-for="notificationNumber">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.notificationNumber') }}
            </template>
            <b-form-input
              id="notificationNumber"
              :value="incorrectInvoiceData?.notificationNumber"
              :placeholder="$t('invoice.incorrectInvoices.ph.notificationNumber')"
              :formatter="onlyUpperCaseFormatter"
              :class="`${incorrectInvoiceData?.notificationNumber ? 'fw-800 text-16px' : ''}`"
              disabled
              :state="errors[0] ? false : null"
            />

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="notificationNumber"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Ngày lập thông báo-->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.dateOfIssuance')"
          rules="required"
          vid="dateOfIssuance"
        >
          <BFormInput
            id="dateOfIssuance"
            :value="incorrectInvoiceData?.dateOfIssuance ?? ''"
            class="d-none"
          />
          <IAmDatePicker
            id="dateOfIssuance"
            :date="incorrectInvoiceData?.dateOfIssuance ?? ''"
            :placeholder="$t('invoice.incorrectInvoices.columns.dateOfIssuance')"
            :disabled="!isEditing"
            required
            :configs="{ maxDate: today }"
            :label="$t('invoice.incorrectInvoices.columns.dateOfIssuance')"
            :error="errors[0]"
            @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, dateOfIssuance: val })"
          />
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Nơi lập-->
      <b-col
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.placeOfIssuance')"
          rules="required"
        >
          <b-form-group
            label-for="placeOfIssuance"
            :class="{ 'is-invalid': errors[0] }"
          >
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.placeOfIssuance') }}
              <span class="text-danger">(*)</span>
            </template>
            <v-select
              id="placeOfIssuance"
              :value="incorrectInvoiceData?.placeOfIssuance"
              :name="$t('invoice.incorrectInvoices.columns.placeOfIssuance')"
              class="w-100 vue-select-class"
              style="font-size: 1rem"
              :options="provinceOptions"
              label="label"
              :reduce="val => val.trim()"
              :disabled="!isEditing"
              :filterable="true"
              :clearable="true"
              :placeholder="$t('placeholderSelect')"
              :loading="loadingProvinceList"
              @open="handleOpenSelectProvince"
              @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, placeOfIssuance: val })"
            >
              <template #option="data">
                {{ data.label }}
              </template>

              <template #selected-option="data">
                {{ data.label }}
              </template>
              <template #no-options>
                {{ $t('noOptions') }}
              </template>
            </v-select>
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="placeOfIssuance"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Số thông báo rà soát của CQT-->
      <!-- Chỉ hiển thị đối với loại thông báo là: Thông báo của NNT theo thông báo rà soát của CQT -->
      <b-col
        v-if="incorrectInvoiceData?.notificationType === INCORRECT_INVOICE_NOTIFICATION_TYPE.TYPE_2"
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber')"
          rules="required"
        >
          <b-form-group label-for="taxReviewNoticeNumber">
            <template #label>
              {{ $t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber') }}
              <span class="text-danger">(*)</span>
            </template>
            <b-form-input
              id="taxReviewNoticeNumber"
              :value="incorrectInvoiceData?.taxReviewNoticeNumber"
              :placeholder="$t('invoice.incorrectInvoices.columns.taxReviewNoticeNumber')"
              :formatter="onlyUpperCaseFormatter"
              :disabled="!isEditing"
              :state="errors[0] ? false : null"
              @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, taxReviewNoticeNumber: val })"
            />

            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              target="taxReviewNoticeNumber"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </b-form-group>
        </ValidationProvider>
      </b-col>

      <!-- ANCHOR Ngày thông báo của CQT-->
      <!-- Chỉ hiển thị đối với loại thông báo là: Thông báo của NNT theo thông báo rà soát của CQT -->
      <b-col
        v-if="incorrectInvoiceData?.notificationType === INCORRECT_INVOICE_NOTIFICATION_TYPE.TYPE_2"
        cols="12"
        md="4"
        class="px-25"
      >
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice')"
          rules="required"
          vid="dateOfTaxReviewNotice"
        >
          <BFormInput
            id="dateOfTaxReviewNotice"
            :value="incorrectInvoiceData?.dateOfTaxReviewNotice ?? ''"
            class="d-none"
          />
          <IAmDatePicker
            id="dateOfTaxReviewNotice"
            :date="incorrectInvoiceData?.dateOfTaxReviewNotice ?? ''"
            :placeholder="$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice')"
            :disabled="!isEditing"
            required
            :label="$t('invoice.incorrectInvoices.columns.dateOfTaxReviewNotice')"
            :error="errors[0]"
            :configs="{ maxDate: today }"
            @input="(val) => $emit('update:incorrectInvoiceData', { ...incorrectInvoiceData, dateOfTaxReviewNotice: val })"
          />
        </ValidationProvider>
      </b-col>
    </b-row>
    <Stack>
      <HStack v-if="taxPayerName">
        <div>
          Tên người nộp thuế:
        </div>
        <div class="fw-700">
          {{ taxPayerName }}
        </div>
      </HStack>
      <HStack v-if="managingTaxAuthorityName">
        <div>
          Tên cơ quan thuế quản lý:
        </div>
        <div class="fw-700">
          {{ managingTaxAuthorityName }}
        </div>
      </HStack>
    </Stack>
  </BCard>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BFormInput,
  BTooltip,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider } from 'vee-validate'
import { computed, ref } from '@vue/composition-api'

import { getToday } from '@/@core/utils/filter'
import { apiInvoice } from '@/api'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import { INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS, INCORRECT_INVOICE_NOTIFICATION_TYPE } from '../useIncorrectInvoiceHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BBadge,
    BFormInput,
    vSelect: () => import('vue-select'),
    ValidationProvider,
    BTooltip,
    IAmDatePicker: () => import('@/components/IAmDatePicker.vue'),
  },
  props: {
    incorrectInvoiceData: {
      type: Object,
      default: () => {},
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    // ANCHOR: xử lí Select Nơi lập
    const provinceOptions = ref([])
    const loadingProvinceList = ref(false)

    async function fetchProvinces() {
      try {
        loadingProvinceList.value = true
        const level = 0
        const res = await apiInvoice.getAdUnitByLevel(level)
        if (res?.data?.items) {
          provinceOptions.value = res.data.items
            .map(province => province?.name)
            .sort((a, b) => a.localeCompare(b))
        }
      } finally {
        loadingProvinceList.value = false
      }
    }

    async function handleOpenSelectProvince() {
      if (!provinceOptions.value.length) {
        await fetchProvinces()
      }
    }

    // const company = computed(() => store.getters['userStore/getAgencyCompany'])
    const taxPayerName = computed(() => props?.incorrectInvoiceData?.taxpayerName) // ?? company.value.name
    const managingTaxAuthorityName = computed(() => props?.incorrectInvoiceData?.managingTaxAuthorityName) // ?? company.value.taxAuthorityName

    return {
      onlyUpperCaseFormatter,
      INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS,
      INCORRECT_INVOICE_NOTIFICATION_TYPE,
      today: getToday('YYYY-MM-DD'),
      provinceOptions,
      handleOpenSelectProvince,
      loadingProvinceList,
      taxPayerName,
      managingTaxAuthorityName,
    }
  },
}
</script>
<style lang="scss" scoped>
.vue-select-class {
  ::v-deep .vs__dropdown-option--selected {
    color: black !important;
    background-color: #DCDCDC70 !important;
    &.vs__dropdown-option--highlight {
      color: black !important;
      background-color: #DCDCDC70 !important;
    }
  }
}

#incorrect-invoice-info-card ::v-deep {
  .height-35px {
    height: 35px;
  }
  .form-group {
    margin-bottom: 0;
  }
}
</style>
