<template lang="">
  <BCard
    body-class="p-50"
    class="mb-0"
  >
    <div
      v-if="!isModal"
      class="d-flex-between gap-1 bg-primary-gradient"
    >
      <div class="d-flex-center justify-content-start gap-1">
        <IAmIcon
          icon="ticket"
          size="25"
          class="p-1 icon-card"
          @dblclick.native.prevent="onQuickChooseDateHandle"
        />
        <span class="font-medium-3 m-0 fw-700">
          {{ $t('invoice.incorrectInvoices.selectInvoice.title') }}
        </span>
      </div>
      <BButton
        variant="flat-dark"
        class="p-50"
        @click="$emit('update:collapseSelectedIncorrectInvoices', !collapseSelectedIncorrectInvoices)"
      >
        <IAmIcon
          icon="resize"
          size="18"
        />
      </BButton>
    </div>
    <!-- SECTION Filter -->
    <BRow class="px-75">
      <!-- ANCHOR Từ ngày -->
      <b-col
        cols="12"
        class="px-25"
        md="4"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0"
          :label="$t('invoice.filters.from')"
        >
          <flat-pickr
            v-model="startDateFilter"
            class="form-control"
            :placeholder="`DD/MM/YYYY`"
            :disabled="!isEditing"
            :config="{
              allowInput: true,
              dateFormat: 'Y-m-d',
              altFormat: 'd/m/Y',
              altInput: true,
              maxDate: endDateFilter ? endDateFilter : today,
              locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
              disableMobile: true,
            }"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Đến ngày -->
      <b-col
        cols="12"
        class="px-25"
        md="4"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0"
          :label="$t('invoice.filters.to')"
        >
          <flat-pickr
            v-model="endDateFilter"
            class="form-control"
            :disabled="!isEditing"
            :placeholder="`DD/MM/YYYY`"
            :config="{
              allowInput: true,
              dateFormat: 'Y-m-d',
              altFormat: 'd/m/Y',
              altInput: true,
              minDate: startDateFilter,
              maxDate: today,
              locale: this.$i18n.locale === 'vi' ? Vietnamese : null,
              disableMobile: true,
            }"
          />
        </b-form-group>
      </b-col>

      <!-- ANCHOR Nhãn -->
      <b-col
        cols="12"
        class="px-25"
        md="4"
      >
        <b-form-group
          class="mb-0"
          label-class="h5 py-0"
          :label="$t('invoice.filters.tags')"
        >
          <v-select
            :value="tagsFilter"
            class="w-100"
            style="font-size: 1rem"
            :options="tagOptions"
            multiple
            taggable
            push-tags
            :disabled="!isEditing"
            label="label"
            clearable
            :placeholder="$t('invoice.create.info.phAssignTag')"
            :reduce="(val) => val"
            :loading="loadingTags"
            @open="onOpenTagHandle"
            @search="onSearchTagHandle"
            @input="onInputTagHandle"
          >
            <template #option="data">
              {{ data.label }}
            </template>

            <template #selected-option="data">
              {{ data.label }}
            </template>
            <template #no-options>
              {{ $t('noOptions') }}
            </template>
            <template #spinner="{ loading: loadingSpinner }">
              <div
                v-if="loadingSpinner"
                style="border-left-color: rgba(88, 151, 251, 0.71)"
                class="vs__spinner"
              />
            </template>
          </v-select>
        </b-form-group>
      </b-col>
      <b-col
        cols="12"
        class="px-25"
      />
    </BRow>
    <!-- !SECTION -->

    <!-- ANCHOR TOP TABLE -->
    <HStack class="my-25">
      <BFormInput
        v-model="searchText"
        class="flex-1"
        :disabled="!isEditing"
        placeholder="Nhập số hóa đơn, tên khách hàng, mã số thuế, mã tra cứu để tìm kiếm..."
        @keyup.enter="refetchData"
      />
      <HStack center>
        <BButton
          variant="info"
          size="sm"
          :disabled="!isEditing"
          @click="refetchData"
        >
          <HStack center>
            <IAmIcon
              icon="search"
              size="15"
              color="white"
            />
            {{ $t('invoice.create.selectTicket.search') }}
          </HStack>
        </BButton>
        <BButton
          variant="danger"
          size="sm"
          :disabled="!isEditing"
          class="d-flex-center gap-1"
          @click="clearFilter"
        >
          <HStack center>
            <IAmIcon
              icon="trash"
              size="15"
              color="white"
            />
            {{ $t('invoice.create.selectTicket.clearFilter') }}
          </HStack>
        </BButton>
      </HStack>
    </HStack>

    <HStack
      end
      class="my-25"
    >
      <BButton
        v-if="!isModal"
        variant="flat-info"
        :disabled="!isEditing"
        size="sm"
        @click="viewDetailsHandle"
      >
        <HStack center>
          <span style="text-decoration: underline">{{ $t('invoice.create.selectTicket.viewDetails') }}</span>
          <IAmIcon
            icon="hyperlink"
            size="16"
            color="#2FA4E5"
          />
        </HStack>
      </BButton>
    </HStack>

    <!-- ANCHOR Table -->
    <b-table
      ref="refInvoiceListTable"
      style="max-height: 70vh"
      :sticky-header="true"
      :class="`position-relative mb-0`"
      :items="fetchInvoices"
      select-mode="multi"
      responsive
      primary-key="id"
      show-empty
      :empty-text="$t('noMatchingResult')"
      no-border-collapse
      small
      borderless
      hover
      :fields="tableColumnsSelectIncorrectInvoice"
      :busy="loading"
      :tbody-tr-class="resolveColorRow"
    >
      <template
        v-for="column in tableColumnsSelectIncorrectInvoice"
        #[`head(${column.key})`]="data"
      >
        <span
          :key="column.key"
          class="text-dark text-nowrap"
        >
          {{ $t(`invoice.columns.${data.column}`) }}
        </span>
      </template>

      <template #table-busy>
        <div class="d-flex-center text-dark my-2 gap-2">
          <b-spinner class="align-middle" />
          <strong>{{ $t('loading') }}</strong>
        </div>
      </template>
      <!-- ANCHOR checkbox -->
      <template #cell(checkbox)="{ item }">
        <b-form-checkbox
          :checked="isInvoiceSelectedCheck(item)"
          :disabled="!isEditing"
          @change="onSelectRow(item)"
        />
      </template>
      <template #cell(symbol)="{ item }">
        {{ item.templateNumber }}{{ item.symbol }}
      </template>

      <template #cell(invNumber)="{ item }">
        <div class="d-flex-center">
          <span
            v-if="item.invNumber"
            class="text-primary fw-700"
          >
            <span> {{ item.invNumber }}</span>
          </span>
          <span
            v-else
            class="text-muted font-italic"
          >
            {{ `<${$t('empty')}>` }}
          </span>
        </div>
      </template>

      <template #cell(buyerName)="{ item }">
        <div class="text-nowrap">
          {{ item.buyerName }}
        </div>
      </template>

      <template #cell(buyerUnitName)="{ item }">
        <div class="width-200 text-justify">
          {{ item.buyerUnitName }}
        </div>
      </template>
      <template #cell(buyerAddress)="{ item }">
        <div class="width-150 text-justify">
          {{ item.buyerAddress }}
        </div>
      </template>

      <template #cell(paymentMethod)="{ item }">
        <div class="width-150 text-justify">
          {{ $t(`invoice.paymentMethodOptions.${item.paymentMethod}`) }}
        </div>
      </template>

      <template #cell(invoiceStatus)="{ item }">
        <Stack>
          <BBadge
            :variant="resolveInvoiceStatus(item?.status ?? item?.invoiceStatus)"
            pill
            class="px-75 fit-content"
          >
            {{ $t(`invoice.invoiceStatus.${item?.status ?? item?.invoiceStatus}`) }}
          </BBadge>

          <BBadge
            v-if="item.createdType === CREATED_TYPE.REQUEST && !isInvoiceRequest"
            variant="light-danger"
            pill
            class="px-75 fit-content"
          >
            {{ $t('invoice.requestInvoice.title') }}
          </BBadge>
        </Stack>
      </template>

      <template #cell(releaseStatus)="{ item }">
        <BBadge
          :variant="resolveReleaseStatus(item.releaseStatus)"
          pill
          class="px-75"
        >
          {{ $t(`invoice.releaseStatus.${item.releaseStatus}`) }}
        </BBadge>
      </template>

      <template #cell(signStatus)="{ item }">
        <BBadge
          :variant="`light-${resolveSignStatus(item.signedAt ? 'SIGNED' : 'UNSIGNED').color}`"
          pill
          class="px-75"
        >
          {{ $t(`invoice.signStatus.${item.signedAt ? 'SIGNED' : 'UNSIGNED'}`) }}
        </BBadge>
      </template>

      <template #cell(sendMailStatus)="{ item }">
        <div class="d-flex-center">
          <BButton
            v-if="resolveSendMailStatus(item?.sendMailStatus)"
            v-b-tooltip.hover.top.window="$t(`invoice.sendMailStatus.${item.sendMailStatus}`)"
            :variant="resolveSendMailStatus(item.sendMailStatus)?.color"
            class="p-25 rounded-circle cursor-default"
          >
            <IAmIcon
              :icon="resolveSendMailStatus(item.sendMailStatus)?.icon"
              size="18"
              color="white"
            />
          </BButton>
        </div>
      </template>

      <template #cell(createdAt)="{ item }">
        <div class="">
          {{ convertISODateTime(item.createdAt).date }}
        </div>
      </template>

      <template #cell(totalAmount)="{ item }">
        <div class="text-right fw-700 text-dark">
          {{ formatCurrency(item.totalAmount) }}
        </div>
      </template>

      <template #cell(tags)="{ item }">
        <div>
          <BBadge
            v-for="tag in item.tags"
            :key="tag"
            variant="light-info"
          >
            {{ tag }}
          </BBadge>
        </div>
      </template>
    </b-table>

    <ModalSelectTicket :is-modal="true" />
  </BCard>
</template>
<script>
import {
  BCard,
  BCol,
  BRow,
  BFormGroup,
  BButton,
  BTable,
  BFormCheckbox,
  BSpinner,
  BBadge,
  BFormInput,
} from 'bootstrap-vue'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'
import moment from 'moment'
import { ref } from '@vue/composition-api'
import { cloneDeep, debounce } from 'lodash-es'

import { convertISODateTime, dateTime, formatCurrency } from '@/@core/utils/filter'
// import { useRouter } from '@/@core/utils/utils'
import useInvoiceListHandle from '@/views/invoices/list/useInvoiceListHandle'
import {
  CREATED_TYPE,
  RELEASE_STATUS,
  INCORRECT_INVOICE_APP_STORE_MODULE_NAME as INCORRECT_INVOICE_STORE,
} from '@/constants/invoice'
import store from '@/store'

import useToast from '@useToast'

import useInvoiceHandle, { resolveInvoiceStatus, resolveReleaseStatus, resolveSendMailStatus } from '../../useInvoiceHandle'

export default {
  components: {
    BCard,
    BCol,
    BRow,
    BFormGroup,
    BButton,
    BTable,
    BFormCheckbox,
    flatPickr,
    BSpinner,
    BBadge,
    BFormInput,
    ModalSelectTicket: () => import('@/views/invoices/components/ModalSelectTicket.vue'),
    vSelect: () => import('vue-select'),
  },
  props: {
    isModal: {
      type: Boolean,
      default: false,
    },
    incorrectInvoiceToCreate: {
      type: Object,
      default: () => {},
    },
    collapseSelectedIncorrectInvoices: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
  },
  setup() {
    // const { route } = useRouter()
    const { toastWarning } = useToast()
    const {
      refInvoiceListTable,
      fetchInvoices,
      loading,
      refetchData,
      clearFilter,
      dataMeta,
      sizePerPage,
      nonePagination,
      currentPage,
      totalInvoices,
      // tableColumnInvoiceList,
      startDate: startDateFilter,
      endDate: endDateFilter,
      tagsFilter,
      releaseStatus,
      searchText,
      isIncorrectNotified,
    } = useInvoiceListHandle()

    // startDateFilter.value = convertISODateTime(new Date()).dateFilter
    nonePagination.value = true
    isIncorrectNotified.value = false // Lọc Hoá đơn chưa được gửi TBSS
    releaseStatus.value = RELEASE_STATUS.CQT_ISSUED_CODE
    endDateFilter.value = convertISODateTime(new Date()).dateFilter
    startDateFilter.value = moment(endDateFilter.value).subtract(30, 'd').format('YYYY-MM-DD')

    // const tableColumnsSelectIncorrectInvoice = computed(() => {
    //   const cols = tableColumnInvoiceList.value.filter(col => ['checkbox', 'symbol', 'invNumber', 'createdAt', 'buyerName', 'buyerUnitName', 'totalAmount'].includes(col.key))
    //   console.log({ cols })
    //   return cols
    // })
    const tableColumnsSelectIncorrectInvoice = ref([
      { key: 'checkbox', stickyColumn: true, tdClass: 'bg-white width-30' },
      { key: 'symbol' }, // Mẫu số/ ký hiệu
      { key: 'invNumber' }, // Số hoá đơn
      { key: 'createdAt' }, // ngày lập
      { key: 'buyerName' }, // Khách hàng (tên người mua)
      { key: 'buyerUnitName' }, // Khách hàng (tên công ty)
      { key: 'totalAmount', stickyColumn: true, class: 'position-right-0' }, // Tổng tiền
    ])
    // includeInvoiceId.value = route.value.params?.id

    // store.dispatch('app-invoice/resetFiltersSaleReport')

    // const isRoleF1 = computed(() => store.getters['userStore/getRoleMama'])
    // const isRoleF2 = computed(() => store.getters['userStore/getRoleF2'])
    // const isRoleF3 = computed(() => store.getters['userStore/getRoleCTV'])
    // const isHideFee = computed(() => store.getters['userStore/getHideFee'])

    // const SALE_REPORT_TABLE_COLUMNS = [
    //   { key: 'checkbox', stickyColumn: true },
    //   { key: 'source' },
    //   { key: 'bookingCode' },
    //   { key: 'paxName' },
    //   { key: 'paxType' },
    //   { key: 'ticketNumber' },
    //   { key: 'trips' },
    //   { key: 'airline' },
    //   { key: 'flightDates' },
    //   { key: 'bookingClass' },
    //   { key: 'paymentCode' },
    //   { key: 'ticketType' },
    //   { key: 'flightType' },
    //   { key: 'fareBasisCode' },
    //   { key: 'bookingDate' },
    //   { key: 'issueDate' },
    //   { key: 'booker' },
    //   { key: 'issuer' },
    //   { key: 'agencyCode' },
    //   { key: 'signBook' },
    //   { key: 'currency' },
    //   { key: 'priceTicket' },
    //   { key: 'tax' },
    //   { key: 'otherTax' },
    //   { key: 'collectionFee' },
    //   { key: 'feeIn' },
    //   { key: 'feeOut' },
    //   { key: 'feeService' },
    //   { key: 'commissionPrepaidIn' },
    //   { key: 'commissionPrepaidOut' },
    //   { key: 'unpaidCommissionIn' },
    //   { key: 'unpaidCommissionOut' },
    //   { key: 'commission' },
    //   { key: 'tradeCreditors' },
    //   { key: 'receivables', stickyColumn: true, class: 'position-right-0' },
    //   { key: 'balanceAdjustment' },
    //   { key: 'profit' },
    //   { key: 'createdAt' },
    //   { key: 'updatedAt' },
    // ]

    // const tableColumnSelectTicket = computed(() => {
    //   let columns = props.isModal
    //     ? SALE_REPORT_TABLE_COLUMNS
    //     : SALE_REPORT_TABLE_COLUMNS.filter(col => [
    //       'checkbox',
    //       'bookingCode',
    //       'paxName',
    //       'ticketNumber',
    //       'trips',
    //       'airline',
    //       'agencyCode',
    //       'ticketType',
    //       'receivables',
    //     ].includes(col.key))

    //   if (isRoleF1.value) {
    //     columns = columns.filter(item => !['tradeCreditors'].includes(item.key))
    //   } else {
    //     columns = columns.filter(item => !['collectionFee', 'tax'].includes(item.key))
    //   }
    //   if (isRoleF3.value) {
    //     const removeField = ['feeIn']
    //     if (isHideFee.value) {
    //       removeField.push('feeOut')
    //     }
    //     columns = columns.filter(item => !removeField.includes(item.key))
    //   }

    //   return columns
    // })

    // const airline = ref()
    // const agencyCode = ref()
    // const ticketType = ref()
    // const bookingCode = ref()
    // const passengerName = ref()
    // const ticketNumber = ref()
    // const selected = ref()
    const now = new Date()
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate())
    function viewDetailsHandle() {
      toastWarning('featureInDevelopment')
      // this.$bvModal.show('modal-invoice-create-select-ticket')
    }

    function onSelectRow(item) {
      store.dispatch(`${INCORRECT_INVOICE_STORE}/setSelectedInvoices`, { item })
    }
    // const invoiceTicketsForCreateInvoice = computed(() => store.getters['app-invoice/getInvoiceTicketsForCreateInvoice'])

    // watch(invoiceTicketsForCreateInvoice, () => {
    //   if (invoiceTicketsForCreateInvoice.value?.length) {
    //     invoiceTicketsForCreateInvoice.value.forEach(item => {
    //       onSelectRow(item, item.valueAddedTax)
    //     })

    //     store.dispatch('app-invoice/setInvoiceTicketsForCreateInvoice', [])
    //   }
    // }, { immediate: true, deep: true })

    function isInvoiceSelectedCheck(item) {
      const res = store.getters[`${INCORRECT_INVOICE_STORE}/findSelectedInvoice`](item)
      return !!res
    }

    // // for agency code filter
    // function getSuggestionValue(suggestion) {
    //   agencyCodeFilter.value = suggestion.item.agencyCode
    //   return suggestion.item.agencyCode
    // }

    // const agencySuggestions = ref([
    //   {
    //     data: [],
    //   },
    // ])
    // const inputSuggestionHandle = debounce(input => {
    //   if (input === '' || input === undefined) {
    //     return
    //   }
    //   apiAgencies
    //     .fetchAgencies({ searchText: input })
    //     .then(response => {
    //       agencySuggestions.value = [
    //         {
    //           data: response.data.items,
    //         },
    //       ]
    //     })
    // }, 300)

    function onQuickChooseDateHandle() {
      startDateFilter.value = moment(endDateFilter.value).subtract(180, 'd').format('YYYY-MM-DD')
      refetchData()
    }

    function resolveColorRow(item) {
      return item?.invoiceId || item?.isInvoiced ? 'bg-light-primary' : ''
    }

    const tagOptions = ref([])
    const tagOptionsDefault = ref([])

    const { loadingTags, getTags } = useInvoiceHandle()
    async function onOpenTagHandle() {
      if (tagOptionsDefault.value.length) {
        tagOptions.value = cloneDeep(tagOptionsDefault.value)
        return
      }
      const res = await getTags()
      tagOptions.value = cloneDeep(res.tags)
      tagOptionsDefault.value = cloneDeep(res.tags)
    }

    function onInputTagHandle(val) {
      tagsFilter.value = val
    }

    const onSearchTagHandle = debounce(async val => {
      const res = await getTags(val)
      tagOptions.value = cloneDeep(res.tags)
      tagOptionsDefault.value = cloneDeep(res.tags)
    }, 300)
    return {
      refInvoiceListTable,
      fetchInvoices,
      loading,
      refetchData,
      clearFilter,
      dataMeta,
      sizePerPage,
      currentPage,
      totalInvoices,
      tableColumnsSelectIncorrectInvoice,
      // onlyUpperCaseFormatter,
      Vietnamese,
      // tableColumnSelectTicket,
      formatCurrency,
      convertISODateTime,
      // airline,
      // agencyCode,
      // ticketType,
      // bookingCode,
      // passengerName,
      // ticketNumber,
      viewDetailsHandle,
      // fetchSaleReports,
      // selected,
      today,
      startDateFilter,
      // flightTypeFilter,
      endDateFilter,
      // ticketNumberFilter,
      // ticketTypeFilter,
      // airlineFilter,
      // paxNameFilter,
      // bookingCodeFilter,
      // agencyCodeFilter,
      // loadingSelectTicket,
      // convertShortenTrips,
      // refTableSelectIncorrectInvoice,
      onSelectRow,
      isInvoiceSelectedCheck,
      // refetchData,
      // flightTypeOptions,
      // saleReportTicketTypeOptions,
      // trimUpperCaseInput,
      // clearFilter,

      // getSuggestionValue,
      // agencySuggestions,
      // inputSuggestionHandle,
      // replaceText,
      dateTime,
      // isRoleF1,
      // isRoleF2,
      // isRoleF3,
      onQuickChooseDateHandle,
      resolveColorRow,
      // isHideTrain: env.isHideTrain,
      resolveInvoiceStatus,
      resolveReleaseStatus,
      resolveSendMailStatus,
      CREATED_TYPE,
      tagsFilter,
      tagOptions,
      onOpenTagHandle,
      onInputTagHandle,
      onSearchTagHandle,
      loadingTags,
      searchText,
    }
  },
}
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>

<style lang="scss" scoped>

.radio-custom, ::v-deep {
  .custom-radio .custom-control-label {
    margin-top: 0;
  }
}
.checkbox-custom ::v-deep {
  .custom-control-label {
    margin-top: 2px;
  }
}
</style>
