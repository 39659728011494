var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "mb-0",
    attrs: {
      "body-class": "p-50"
    }
  }, [!_vm.isModal ? _c('div', {
    staticClass: "d-flex-between gap-1 bg-primary-gradient"
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "ticket",
      "size": "25"
    },
    nativeOn: {
      "dblclick": function dblclick($event) {
        $event.preventDefault();
        return _vm.onQuickChooseDateHandle($event);
      }
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.selectInvoice.title')) + " ")])], 1), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('update:collapseSelectedIncorrectInvoices', !_vm.collapseSelectedIncorrectInvoices);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "resize",
      "size": "18"
    }
  })], 1)], 1) : _vm._e(), _c('BRow', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.from')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "DD/MM/YYYY",
      "disabled": !_vm.isEditing,
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDateFilter ? _vm.endDateFilter : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.startDateFilter,
      callback: function callback($$v) {
        _vm.startDateFilter = $$v;
      },
      expression: "startDateFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.to')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "disabled": !_vm.isEditing,
      "placeholder": "DD/MM/YYYY",
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.startDateFilter,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.endDateFilter,
      callback: function callback($$v) {
        _vm.endDateFilter = $$v;
      },
      expression: "endDateFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.tags')
    }
  }, [_c('v-select', {
    staticClass: "w-100",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "value": _vm.tagsFilter,
      "options": _vm.tagOptions,
      "multiple": "",
      "taggable": "",
      "push-tags": "",
      "disabled": !_vm.isEditing,
      "label": "label",
      "clearable": "",
      "placeholder": _vm.$t('invoice.create.info.phAssignTag'),
      "reduce": function reduce(val) {
        return val;
      },
      "loading": _vm.loadingTags
    },
    on: {
      "open": _vm.onOpenTagHandle,
      "search": _vm.onSearchTagHandle,
      "input": _vm.onInputTagHandle
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(data.label) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }, {
      key: "spinner",
      fn: function fn(_ref) {
        var loadingSpinner = _ref.loading;
        return [loadingSpinner ? _c('div', {
          staticClass: "vs__spinner",
          staticStyle: {
            "border-left-color": "rgba(88, 151, 251, 0.71)"
          }
        }) : _vm._e()];
      }
    }])
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12"
    }
  })], 1), _c('HStack', {
    staticClass: "my-25"
  }, [_c('BFormInput', {
    staticClass: "flex-1",
    attrs: {
      "disabled": !_vm.isEditing,
      "placeholder": "Nhập số hóa đơn, tên khách hàng, mã số thuế, mã tra cứu để tìm kiếm..."
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.searchText,
      callback: function callback($$v) {
        _vm.searchText = $$v;
      },
      expression: "searchText"
    }
  }), _c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('BButton', {
    attrs: {
      "variant": "info",
      "size": "sm",
      "disabled": !_vm.isEditing
    },
    on: {
      "click": _vm.refetchData
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "search",
      "size": "15",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.search')) + " ")], 1)], 1), _c('BButton', {
    staticClass: "d-flex-center gap-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": !_vm.isEditing
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash",
      "size": "15",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.clearFilter')) + " ")], 1)], 1)], 1)], 1), _c('HStack', {
    staticClass: "my-25",
    attrs: {
      "end": ""
    }
  }, [!_vm.isModal ? _c('BButton', {
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.isEditing,
      "size": "sm"
    },
    on: {
      "click": _vm.viewDetailsHandle
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('span', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.selectTicket.viewDetails')))]), _c('IAmIcon', {
    attrs: {
      "icon": "hyperlink",
      "size": "16",
      "color": "#2FA4E5"
    }
  })], 1)], 1) : _vm._e()], 1), _c('b-table', {
    ref: "refInvoiceListTable",
    class: "position-relative mb-0",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchInvoices,
      "select-mode": "multi",
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "borderless": "",
      "hover": "",
      "fields": _vm.tableColumnsSelectIncorrectInvoice,
      "busy": _vm.loading,
      "tbody-tr-class": _vm.resolveColorRow
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsSelectIncorrectInvoice, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.isInvoiceSelectedCheck(item),
            "disabled": !_vm.isEditing
          },
          on: {
            "change": function change($event) {
              return _vm.onSelectRow(item);
            }
          }
        })];
      }
    }, {
      key: "cell(symbol)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_vm._v(" " + _vm._s(item.templateNumber) + _vm._s(item.symbol) + " ")];
      }
    }, {
      key: "cell(invNumber)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [item.invNumber ? _c('span', {
          staticClass: "text-primary fw-700"
        }, [_c('span', [_vm._v(" " + _vm._s(item.invNumber))])]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(buyerName)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.buyerName) + " ")])];
      }
    }, {
      key: "cell(buyerUnitName)",
      fn: function fn(_ref6) {
        var item = _ref6.item;
        return [_c('div', {
          staticClass: "width-200 text-justify"
        }, [_vm._v(" " + _vm._s(item.buyerUnitName) + " ")])];
      }
    }, {
      key: "cell(buyerAddress)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "width-150 text-justify"
        }, [_vm._v(" " + _vm._s(item.buyerAddress) + " ")])];
      }
    }, {
      key: "cell(paymentMethod)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "width-150 text-justify"
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.paymentMethodOptions.".concat(item.paymentMethod))) + " ")])];
      }
    }, {
      key: "cell(invoiceStatus)",
      fn: function fn(_ref9) {
        var _item$status, _item$status2;
        var item = _ref9.item;
        return [_c('Stack', [_c('BBadge', {
          staticClass: "px-75 fit-content",
          attrs: {
            "variant": _vm.resolveInvoiceStatus((_item$status = item === null || item === void 0 ? void 0 : item.status) !== null && _item$status !== void 0 ? _item$status : item === null || item === void 0 ? void 0 : item.invoiceStatus),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceStatus.".concat((_item$status2 = item === null || item === void 0 ? void 0 : item.status) !== null && _item$status2 !== void 0 ? _item$status2 : item === null || item === void 0 ? void 0 : item.invoiceStatus))) + " ")]), item.createdType === _vm.CREATED_TYPE.REQUEST && !_vm.isInvoiceRequest ? _c('BBadge', {
          staticClass: "px-75 fit-content",
          attrs: {
            "variant": "light-danger",
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t('invoice.requestInvoice.title')) + " ")]) : _vm._e()], 1)];
      }
    }, {
      key: "cell(releaseStatus)",
      fn: function fn(_ref10) {
        var item = _ref10.item;
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": _vm.resolveReleaseStatus(item.releaseStatus),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.releaseStatus.".concat(item.releaseStatus))) + " ")])];
      }
    }, {
      key: "cell(signStatus)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('BBadge', {
          staticClass: "px-75",
          attrs: {
            "variant": "light-".concat(_vm.resolveSignStatus(item.signedAt ? 'SIGNED' : 'UNSIGNED').color),
            "pill": ""
          }
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.signStatus.".concat(item.signedAt ? 'SIGNED' : 'UNSIGNED'))) + " ")])];
      }
    }, {
      key: "cell(sendMailStatus)",
      fn: function fn(_ref12) {
        var _vm$resolveSendMailSt, _vm$resolveSendMailSt2;
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "d-flex-center"
        }, [_vm.resolveSendMailStatus(item === null || item === void 0 ? void 0 : item.sendMailStatus) ? _c('BButton', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip.hover.top.window",
            value: _vm.$t("invoice.sendMailStatus.".concat(item.sendMailStatus)),
            expression: "$t(`invoice.sendMailStatus.${item.sendMailStatus}`)",
            modifiers: {
              "hover": true,
              "top": true,
              "window": true
            }
          }],
          staticClass: "p-25 rounded-circle cursor-default",
          attrs: {
            "variant": (_vm$resolveSendMailSt = _vm.resolveSendMailStatus(item.sendMailStatus)) === null || _vm$resolveSendMailSt === void 0 ? void 0 : _vm$resolveSendMailSt.color
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": (_vm$resolveSendMailSt2 = _vm.resolveSendMailStatus(item.sendMailStatus)) === null || _vm$resolveSendMailSt2 === void 0 ? void 0 : _vm$resolveSendMailSt2.icon,
            "size": "18",
            "color": "white"
          }
        })], 1) : _vm._e()], 1)];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref13) {
        var item = _ref13.item;
        return [_c('div', {}, [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")])];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "text-right fw-700 text-dark"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.totalAmount)) + " ")])];
      }
    }, {
      key: "cell(tags)",
      fn: function fn(_ref15) {
        var item = _ref15.item;
        return [_c('div', _vm._l(item.tags, function (tag) {
          return _c('BBadge', {
            key: tag,
            attrs: {
              "variant": "light-info"
            }
          }, [_vm._v(" " + _vm._s(tag) + " ")]);
        }), 1)];
      }
    }], null, true)
  }), _c('ModalSelectTicket', {
    attrs: {
      "is-modal": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }