var render = function () {
  var _vm$incorrectInvoices;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-modal', {
    attrs: {
      "id": "modal-send-incorrect-invoice-emails",
      "size": "xl",
      "centered": "",
      "header-bg-variant": "light-primary",
      "no-close-on-backdrop": "",
      "no-close-on-esc": "",
      "body-class": "p-1",
      "title-class": "h3 font-weight-bolder text-dark",
      "title": _vm.$t('invoice.incorrectInvoices.sendEmailModal.title'),
      "hide-footer": ""
    },
    on: {
      "show": _vm.onShowHandle
    }
  }, [_c('ValidationObserver', {
    ref: "refFormObserver"
  }, [(_vm$incorrectInvoices = _vm.incorrectInvoices) !== null && _vm$incorrectInvoices !== void 0 && _vm$incorrectInvoices.length ? _c('Stack', {
    staticClass: "gap-2",
    attrs: {
      "id": "div-table"
    }
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.sendEmailModal.headerTable')) + " ")]), _vm._l(_vm.incorrectInvoices, function (incorrectInvoice, incorrectInvoiceId) {
    var _incorrectInvoice$inv;
    return _c('div', {
      key: incorrectInvoiceId
    }, [_c('b-table', {
      ref: "refIncorrectInvoiceListTable",
      refInFor: true,
      staticClass: "position-relative",
      staticStyle: {
        "max-height": "70vh"
      },
      attrs: {
        "sticky-header": true,
        "items": (_incorrectInvoice$inv = incorrectInvoice === null || incorrectInvoice === void 0 ? void 0 : incorrectInvoice.invoiceIncorrectItems) !== null && _incorrectInvoice$inv !== void 0 ? _incorrectInvoice$inv : [],
        "responsive": "",
        "primary-key": "id",
        "show-empty": "",
        "empty-text": _vm.$t('noMatchingResult'),
        "no-border-collapse": "",
        "small": "",
        "bordered": "",
        "fields": _vm.tableColumnSendEmails
      },
      scopedSlots: _vm._u([_vm._l(_vm.tableColumnSendEmails, function (column) {
        return {
          key: "head(".concat(column.key, ")"),
          fn: function fn(data) {
            return [_c('HStack', {
              key: column.key,
              staticClass: "text-wrap text-center",
              attrs: {
                "center": ""
              }
            }, [_c('div', [_vm._v(_vm._s(_vm.$t("invoice.incorrectInvoices.sendEmailModal.columns.".concat(data.column))))]), ['email', 'recipientName'].includes(data.column) ? _c('span', {
              staticClass: "text-danger"
            }, [_vm._v("(*)")]) : _vm._e()])];
          }
        };
      }), {
        key: "cell(no)",
        fn: function fn(row) {
          return [_c('div', {
            staticClass: "text-right"
          }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
        }
      }, {
        key: "cell(templateNumberAndSymbol)",
        fn: function fn(_ref) {
          var item = _ref.item;
          return [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol'),
              "rules": "required",
              "vid": "templateNumberAndSymbol-".concat(item.id)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var errors = _ref2.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                  staticStyle: {
                    "overflow": "auto"
                  },
                  attrs: {
                    "id": "templateNumberAndSymbol-".concat(item.id),
                    "value": item.templateNumberAndSymbol,
                    "name": _vm.$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol'),
                    "state": errors[0] ? false : null,
                    "disabled": "",
                    "placeholder": _vm.$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "templateNumberAndSymbol-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })];
        }
      }, {
        key: "cell(invoiceNumber)",
        fn: function fn(_ref3) {
          var item = _ref3.item;
          return [_c('div', {
            staticClass: "width-100"
          }, [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.columns.invNumber'),
              "vid": "invNumber-".concat(item.id),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref4) {
                var errors = _ref4.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                  staticStyle: {
                    "overflow": "auto"
                  },
                  attrs: {
                    "id": "invNumber-".concat(item.id),
                    "value": item.invoiceNumber,
                    "disabled": "",
                    "name": _vm.$t('invoice.incorrectInvoices.columns.invNumber'),
                    "state": errors[0] ? false : null,
                    "placeholder": _vm.$t('invoice.incorrectInvoices.columns.invNumber')
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "invNumber-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1)];
        }
      }, {
        key: "cell(invoiceType)",
        fn: function fn(_ref5) {
          var item = _ref5.item;
          return [_c('BFormGroup', [_c('BFormInput', {
            staticClass: "fw-700 border-transparent",
            staticStyle: {
              "overflow": "auto"
            },
            attrs: {
              "id": "templateNumberAndSymbol-".concat(item.id),
              "value": item.isInternal ? 'Trong hệ thống' : 'Ngoài hệ thống',
              "disabled": ""
            }
          })], 1)];
        }
      }, {
        key: "cell(invoiceDate)",
        fn: function fn(_ref6) {
          var item = _ref6.item;
          return [_c('div', {
            staticClass: "width-100"
          }, [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.columns.invoiceDate'),
              "rules": "required",
              "vid": "invoiceDate-".concat(item.id)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref7) {
                var errors = _ref7.errors;
                return [_c('BFormGroup', {
                  attrs: {
                    "id": "form-group-invoiceDate-".concat(item.id),
                    "label-for": "dateOfIssuance"
                  }
                }, [_c('BFormInput', {
                  staticClass: "d-none",
                  attrs: {
                    "value": item.invoiceDate
                  }
                }), _c('div', {
                  class: "form-control ".concat(errors[0] ? 'is-invalid' : 'border-transparent', " p-0")
                }, [_c('flat-pickr', {
                  attrs: {
                    "id": "invoiceDate-".concat(item.id),
                    "value": _vm.convertISODateTime(item.invoiceDate).dateFilter,
                    "disabled": "",
                    "name": _vm.$t('invoice.incorrectInvoices.columns.invoiceDate'),
                    "placeholder": "".concat(_vm.$t('invoice.incorrectInvoices.columns.invoiceDate')),
                    "config": {
                      allowInput: true,
                      dateFormat: 'Y-m-d',
                      altFormat: 'd/m/Y',
                      altInput: true,
                      maxDate: _vm.today,
                      locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
                      disableMobile: true,
                      altInputClass: 'form-control input px-50 py-0 border-transparent height-35px'
                    }
                  }
                })], 1), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "form-group-invoiceDate-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1)];
        }
      }, {
        key: "cell(invoiceBuyerUnitName)",
        fn: function fn(_ref8) {
          var item = _ref8.item;
          return [_c('BFormGroup', [_c('BFormInput', {
            staticClass: "fw-700 border-transparent",
            staticStyle: {
              "overflow": "auto"
            },
            attrs: {
              "value": item.invoiceBuyerUnitName,
              "disabled": ""
            }
          })], 1)];
        }
      }, {
        key: "cell(amount)",
        fn: function fn(_ref9) {
          var item = _ref9.item;
          return [_c('div', {}, [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.columns.amount'),
              "rules": "required|min_value:0"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref10) {
                var errors = _ref10.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  staticClass: "d-none",
                  attrs: {
                    "value": item.amount,
                    "disabled": "",
                    "name": _vm.$t('invoice.incorrectInvoices.columns.amount')
                  }
                }), _c('IAmInputMoney', {
                  attrs: {
                    "id": "amount-".concat(item.id),
                    "value-money": item.amount,
                    "canInputNegative": true,
                    "disabled": "",
                    "custom-class": "".concat(!errors[0] ? 'border-transparent' : ''),
                    "state": errors[0] ? false : null,
                    "name": _vm.$t('invoice.incorrectInvoices.columns.amount')
                  },
                  on: {
                    "update:valueMoney": function updateValueMoney($event) {
                      return _vm.$set(item, "amount", $event);
                    },
                    "update:value-money": function updateValueMoney($event) {
                      return _vm.$set(item, "amount", $event);
                    }
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "amount-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1)];
        }
      }, {
        key: "cell(reason)",
        fn: function fn(_ref11) {
          var item = _ref11.item;
          return [_c('div', {}, [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.columns.reason'),
              "vid": "reason-".concat(item.id),
              "rules": "required"
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref12) {
                var errors = _ref12.errors;
                return [_c('BFormInput', {
                  staticClass: "d-none",
                  attrs: {
                    "value": item.reason
                  }
                }), _c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('IAmInputTextOrTextarea', {
                  class: {
                    'border-transparent': !errors[0]
                  },
                  attrs: {
                    "id": "reason-".concat(item.id),
                    "text": item.reason,
                    "state": errors[0] ? false : null,
                    "type": "text",
                    "name": _vm.$t('invoice.incorrectInvoices.columns.reason'),
                    "disabled": "",
                    "rows": "1",
                    "max-rows": "4",
                    "placeholder": _vm.$t('invoice.incorrectInvoices.ph.reason')
                  },
                  on: {
                    "update:text": function updateText($event) {
                      return _vm.$set(item, "reason", $event);
                    }
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "reason-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })], 1)];
        }
      }, {
        key: "cell(incorrectInvoiceStatus)",
        fn: function fn() {
          return [_c('HStack', {
            attrs: {
              "center": ""
            }
          }, [_c('BBadge', {
            staticClass: "px-75 fit-content",
            attrs: {
              "variant": _vm.resolveNotificationStatusVariant(incorrectInvoice === null || incorrectInvoice === void 0 ? void 0 : incorrectInvoice.status),
              "pill": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.notificationStatus.".concat(incorrectInvoice === null || incorrectInvoice === void 0 ? void 0 : incorrectInvoice.status))) + " ")])], 1)];
        },
        proxy: true
      }, {
        key: "cell(recipientName)",
        fn: function fn(_ref13) {
          var item = _ref13.item;
          return [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.recipientName'),
              "rules": "required",
              "vid": "recipientName-".concat(item.id)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref14) {
                var errors = _ref14.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                  staticStyle: {
                    "overflow": "auto"
                  },
                  attrs: {
                    "id": "recipientName-".concat(item.id),
                    "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.recipientName'),
                    "state": errors[0] ? false : null,
                    "placeholder": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.recipientName')
                  },
                  model: {
                    value: item.invoiceBuyerName,
                    callback: function callback($$v) {
                      _vm.$set(item, "invoiceBuyerName", $$v);
                    },
                    expression: "item.invoiceBuyerName"
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "recipientName-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })];
        }
      }, {
        key: "cell(email)",
        fn: function fn(_ref15) {
          var item = _ref15.item;
          return [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.email'),
              "rules": "required|email",
              "vid": "email-".concat(item.id)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref16) {
                var errors = _ref16.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                  staticStyle: {
                    "overflow": "auto"
                  },
                  attrs: {
                    "id": "email-".concat(item.id),
                    "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.email'),
                    "state": errors[0] ? false : null,
                    "placeholder": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.email')
                  },
                  model: {
                    value: item.email,
                    callback: function callback($$v) {
                      _vm.$set(item, "email", $$v);
                    },
                    expression: "item.email"
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "email-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })];
        }
      }, {
        key: "cell(cc)",
        fn: function fn(_ref17) {
          var item = _ref17.item;
          return [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.cc'),
              "rules": "email",
              "vid": "cc-".concat(item.id)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref18) {
                var errors = _ref18.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                  staticStyle: {
                    "overflow": "auto"
                  },
                  attrs: {
                    "id": "cc-".concat(item.id),
                    "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.cc'),
                    "state": errors[0] ? false : null,
                    "placeholder": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.cc')
                  },
                  model: {
                    value: item.cc,
                    callback: function callback($$v) {
                      _vm.$set(item, "cc", $$v);
                    },
                    expression: "item.cc"
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "cc-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })];
        }
      }, {
        key: "cell(bcc)",
        fn: function fn(_ref19) {
          var item = _ref19.item;
          return [_c('ValidationProvider', {
            attrs: {
              "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.bcc'),
              "rules": "bcc",
              "vid": "bcc-".concat(item.id)
            },
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref20) {
                var errors = _ref20.errors;
                return [_c('BFormGroup', {
                  class: {
                    'is-invalid': errors[0]
                  }
                }, [_c('BFormInput', {
                  class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                  staticStyle: {
                    "overflow": "auto"
                  },
                  attrs: {
                    "id": "bcc-".concat(item.id),
                    "name": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.bcc'),
                    "state": errors[0] ? false : null,
                    "placeholder": _vm.$t('invoice.incorrectInvoices.sendEmailModal.columns.bcc')
                  },
                  model: {
                    value: item.bcc,
                    callback: function callback($$v) {
                      _vm.$set(item, "bcc", $$v);
                    },
                    expression: "item.bcc"
                  }
                }), errors[0] ? _c('b-tooltip', {
                  attrs: {
                    "variant": "danger",
                    "boundary": "window",
                    "target": "bcc-".concat(item.id),
                    "triggers": "hover"
                  }
                }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
              }
            }], null, true)
          })];
        }
      }], null, true)
    })], 1);
  }), _c('div', [_c('BButton', {
    staticClass: "px-50",
    attrs: {
      "disabled": "",
      "size": "sm",
      "variant": "outline-primary"
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "newFileOutline",
      "size": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.sendEmailModal.attachNotiDocuments')) + " ")], 1)], 1)], 1), _c('BFormCheckbox', {
    model: {
      value: _vm.isAttachErrorAndReceipt,
      callback: function callback($$v) {
        _vm.isAttachErrorAndReceipt = $$v;
      },
      expression: "isAttachErrorAndReceipt"
    }
  }, [_c('span', {
    staticClass: "fw-700 font-medium-2"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.sendEmailModal.attachErrorAndReceipt')) + " ")])]), _vm.isAttachErrorAndReceipt ? _c('div', {
    staticClass: "fw-700"
  }, _vm._l(_vm.incorrectInvoices, function (incorrectInvoice, incorrectInvoiceId) {
    return _c('div', {
      key: incorrectInvoiceId
    }, [_vm._v(" " + _vm._s(incorrectInvoice.notificationNumber) + " ")]);
  }), 0) : _vm._e()], 2) : _vm._e(), _c('HStack', {
    staticClass: "mt-1 gap-3",
    attrs: {
      "center": ""
    }
  }, [_c('BButton', {
    staticClass: "width-150",
    attrs: {
      "variant": "secondary"
    },
    on: {
      "click": _vm.onCloseHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('close')) + " ")]), _c('BButton', {
    staticClass: "width-150",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.onSendMailsHandle
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('submit')) + " ")])], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }