var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    attrs: {
      "id": "incorrect-invoice-detail-card",
      "body-class": "p-50"
    }
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1 bg-primary-gradient"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "infoCircleOutline",
      "size": "25"
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.incorrectInvoices.detailCard.title')) + " ")])], 1), _c('b-table', {
    class: "position-relative my-50 ".concat(_vm.isInvalidIncorrectInvoiceItems ? 'border-danger rounded' : ''),
    style: "max-height: 800px; ".concat(!_vm.isEditing ? 'background-color: #f8f8f8' : ''),
    attrs: {
      "sticky-header": true,
      "items": _vm.selectedInvoices,
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('invoice.incorrectInvoices.msg.pleaseChooseInvoices'),
      "no-border-collapse": "",
      "small": "",
      "bordered": "",
      "tbody-tr-class": {
        'bg-disabled': !_vm.isEditing
      },
      "fields": _vm.tableColumnIncorrectInvoices
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnIncorrectInvoices, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          var _column$thClass;
          return [_c('Stack', {
            key: column.key,
            class: "text-dark ".concat((_column$thClass = column.thClass) !== null && _column$thClass !== void 0 ? _column$thClass : 'text-nowrap', " d-flex-center"),
            attrs: {
              "center": ""
            }
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "empty",
      fn: function fn(scope) {
        return [_c('b-alert', {
          staticClass: "px-2 py-1 mb-0",
          attrs: {
            "show": "",
            "variant": "warning"
          }
        }, [_vm._v(" " + _vm._s(scope.emptyText) + " ")])];
      }
    }, {
      key: "cell(no)",
      fn: function fn(row) {
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(row.index + 1) + " ")])];
      }
    }, {
      key: "cell(templateNumberAndSymbol)",
      fn: function fn(_ref) {
        var item = _ref.item;
        return [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol'),
            "rules": "required",
            "vid": "templateNumberAndSymbol-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref2) {
              var errors = _ref2.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "templateNumberAndSymbol-".concat(item.id),
                  "disabled": !_vm.isEditing,
                  "name": _vm.$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol'),
                  "state": errors[0] ? false : null,
                  "placeholder": _vm.$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')
                },
                model: {
                  value: item.templateNumberAndSymbol,
                  callback: function callback($$v) {
                    _vm.$set(item, "templateNumberAndSymbol", $$v);
                  },
                  expression: "item.templateNumberAndSymbol"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "templateNumberAndSymbol-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })];
      }
    }, {
      key: "cell(invNumber)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "width-120"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.invNumber'),
            "vid": "invNumber-".concat(item.id),
            "rules": "required|isUniqueTicketNumber:".concat(_vm.selectedInvoices.map(function (i) {
              return i === null || i === void 0 ? void 0 : i.invoiceNumber;
            }), "|validTicketNumber:").concat(_vm.invalidInvoiceNumbers)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref4) {
              var errors = _ref4.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                class: "fw-700 ".concat(!errors[0] ? 'border-transparent' : ''),
                staticStyle: {
                  "overflow": "auto"
                },
                attrs: {
                  "id": "invNumber-".concat(item.id),
                  "disabled": !_vm.isEditing,
                  "name": _vm.$t('invoice.incorrectInvoices.columns.invNumber'),
                  "state": errors[0] ? false : null,
                  "placeholder": _vm.$t('invoice.incorrectInvoices.columns.invNumber')
                },
                on: {
                  "input": function input(val) {
                    return _vm.onInvoiceNumberChange(val, item);
                  }
                },
                model: {
                  value: item.invoiceNumber,
                  callback: function callback($$v) {
                    _vm.$set(item, "invoiceNumber", $$v);
                  },
                  expression: "item.invoiceNumber"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "invNumber-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(amount)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "width-120"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.amount'),
            "rules": "required|min_value:0"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref6) {
              var errors = _ref6.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                staticClass: "d-none",
                attrs: {
                  "value": item.amount,
                  "disabled": "",
                  "name": _vm.$t('invoice.incorrectInvoices.columns.amount')
                }
              }), _c('IAmInputMoney', {
                attrs: {
                  "id": "amount-".concat(item.id),
                  "value-money": item.amount,
                  "disabled": !_vm.isEditing,
                  "canInputNegative": true,
                  "custom-class": "".concat(!errors[0] ? 'border-transparent' : ''),
                  "state": errors[0] ? false : null,
                  "name": _vm.$t('invoice.incorrectInvoices.columns.amount')
                },
                on: {
                  "update:valueMoney": function updateValueMoney($event) {
                    return _vm.$set(item, "amount", $event);
                  },
                  "update:value-money": function updateValueMoney($event) {
                    return _vm.$set(item, "amount", $event);
                  }
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "amount-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(invoiceDate)",
      fn: function fn(_ref7) {
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "width-100"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.invoiceDate'),
            "rules": "required",
            "vid": "invoiceDate-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref8) {
              var errors = _ref8.errors;
              return [_c('BFormGroup', {
                attrs: {
                  "id": "form-group-invoiceDate-".concat(item.id),
                  "label-for": "dateOfIssuance"
                }
              }, [_c('BFormInput', {
                staticClass: "d-none",
                attrs: {
                  "value": item.invoiceDate
                }
              }), _c('div', {
                class: "form-control ".concat(errors[0] ? 'is-invalid' : 'border-transparent', " p-0")
              }, [_c('flat-pickr', {
                attrs: {
                  "id": "invoiceDate-".concat(item.id),
                  "name": _vm.$t('invoice.incorrectInvoices.columns.invoiceDate'),
                  "disabled": !_vm.isEditing,
                  "placeholder": "".concat(_vm.$t('invoice.incorrectInvoices.columns.invoiceDate')),
                  "config": {
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    maxDate: _vm.today,
                    locale: _vm.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
                    disableMobile: true,
                    altInputClass: 'form-control input px-50 py-0 border-transparent height-35px'
                  }
                },
                model: {
                  value: item.invoiceDate,
                  callback: function callback($$v) {
                    _vm.$set(item, "invoiceDate", $$v);
                  },
                  expression: "item.invoiceDate"
                }
              })], 1), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "form-group-invoiceDate-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(taxAuthorityCode)",
      fn: function fn(_ref9) {
        var item = _ref9.item;
        return [_c('div', {
          staticClass: "width-120"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.taxAuthorityCode'),
            "rules": "required",
            "vid": "taxAuthorityCode-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref10) {
              var errors = _ref10.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('BFormInput', {
                class: {
                  'border-transparent': !errors[0]
                },
                attrs: {
                  "id": "taxAuthorityCode-".concat(item.id),
                  "state": errors[0] ? false : null,
                  "disabled": !_vm.isEditing,
                  "formatter": function formatter(value) {
                    return value.trim();
                  },
                  "name": _vm.$t('invoice.incorrectInvoices.columns.taxAuthorityCode'),
                  "placeholder": _vm.$t('invoice.incorrectInvoices.ph.taxAuthorityCode')
                },
                model: {
                  value: item.taxAuthorityCode,
                  callback: function callback($$v) {
                    _vm.$set(item, "taxAuthorityCode", $$v);
                  },
                  expression: "item.taxAuthorityCode"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "taxAuthorityCode-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(typeOfInvoiceApplication)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', {
          staticClass: "custom-v-select-type-of-invoice-application width-150"
        }, [_c('BFormGroup', [_c('v-select', {
          staticClass: "border-transparent w-100",
          attrs: {
            "options": _vm.TYPE_OF_INVOICE_APPLICATION_OPTIONS,
            "clearable": false,
            "append-to-body": "",
            "calculate-position": function calculatePosition(dropdownList, component) {
              return _vm.withPopper(dropdownList, component, {
                width: '450px'
              });
            },
            "label": "label",
            "disabled": !_vm.isEditing,
            "reduce": function reduce(val) {
              return val.value;
            }
          },
          scopedSlots: _vm._u([{
            key: "option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.typeOfInvoiceApplication.".concat(data.label))) + " ")])];
            }
          }, {
            key: "selected-option",
            fn: function fn(data) {
              return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.typeOfInvoiceApplication.".concat(data.label))) + " ")])];
            }
          }, {
            key: "no-options",
            fn: function fn() {
              return [_vm._v(" " + _vm._s(_vm.$t('selectOptions.noOptions')) + " ")];
            },
            proxy: true
          }], null, true),
          model: {
            value: item.typeOfInvoiceApplication,
            callback: function callback($$v) {
              _vm.$set(item, "typeOfInvoiceApplication", $$v);
            },
            expression: "item.typeOfInvoiceApplication"
          }
        })], 1)], 1)];
      }
    }, {
      key: "cell(incorrectInvoiceAction)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', {
          staticClass: "custom-v-select-incorrect-invoice-action",
          staticStyle: {
            "width": "150px"
          }
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.incorrectInvoiceAction'),
            "rules": "required",
            "vid": "incorrectInvoiceAction-".concat(item.id)
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref13) {
              var errors = _ref13.errors;
              return [_c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('v-select', {
                staticClass: "w-100",
                staticStyle: {
                  "font-size": "1rem"
                },
                attrs: {
                  "id": "incorrectInvoiceAction-".concat(item.id),
                  "name": _vm.$t('invoice.incorrectInvoices.columns.incorrectInvoiceAction'),
                  "options": _vm.INCORRECT_INVOICE_ACTION_OPTIONS,
                  "clearable": true,
                  "label": "label",
                  "disabled": !_vm.isEditing,
                  "reduce": function reduce(val) {
                    return val.value;
                  },
                  "append-to-body": "",
                  "calculate-position": function calculatePosition(dropdownList, component) {
                    return _vm.withPopper(dropdownList, component, {
                      width: '150px'
                    });
                  },
                  "placeholder": _vm.$t('invoice.incorrectInvoices.ph.incorrectInvoiceAction')
                },
                scopedSlots: _vm._u([{
                  key: "option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.incorrectInvoiceAction.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "selected-option",
                  fn: function fn(data) {
                    return [_c('span', [_vm._v(" " + _vm._s(_vm.$t("invoice.incorrectInvoices.incorrectInvoiceAction.".concat(data.label))) + " ")])];
                  }
                }, {
                  key: "no-options",
                  fn: function fn() {
                    return [_vm._v(" " + _vm._s(_vm.$t('selectOptions.noOptions')) + " ")];
                  },
                  proxy: true
                }], null, true),
                model: {
                  value: item.action,
                  callback: function callback($$v) {
                    _vm.$set(item, "action", $$v);
                  },
                  expression: "item.action"
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "incorrectInvoiceAction-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(reason)",
      fn: function fn(_ref14) {
        var item = _ref14.item;
        return [_c('div', {
          staticClass: "width-150"
        }, [_c('ValidationProvider', {
          attrs: {
            "name": _vm.$t('invoice.incorrectInvoices.columns.reason'),
            "vid": "reason-".concat(item.id),
            "rules": "required"
          },
          scopedSlots: _vm._u([{
            key: "default",
            fn: function fn(_ref15) {
              var errors = _ref15.errors;
              return [_c('BFormInput', {
                staticClass: "d-none",
                attrs: {
                  "value": item.reason
                }
              }), _c('BFormGroup', {
                class: {
                  'is-invalid': errors[0]
                }
              }, [_c('IAmInputTextOrTextarea', {
                class: {
                  'border-transparent': !errors[0]
                },
                attrs: {
                  "id": "reason-".concat(item.id),
                  "text": item.reason,
                  "state": errors[0] ? false : null,
                  "disabled": !_vm.isEditing,
                  "type": "text",
                  "name": _vm.$t('invoice.incorrectInvoices.columns.reason'),
                  "rows": "1",
                  "max-rows": "4",
                  "placeholder": _vm.$t('invoice.incorrectInvoices.ph.reason')
                },
                on: {
                  "update:text": function updateText($event) {
                    return _vm.$set(item, "reason", $event);
                  }
                }
              }), errors[0] ? _c('b-tooltip', {
                attrs: {
                  "variant": "danger",
                  "boundary": "window",
                  "target": "reason-".concat(item.id),
                  "triggers": "hover"
                }
              }, [_vm._v(" " + _vm._s(errors[0]) + " ")]) : _vm._e()], 1)];
            }
          }], null, true)
        })], 1)];
      }
    }, {
      key: "cell(actionNoTitle)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('HStack', [_c('BButton', {
          staticClass: "p-50 rounded-circle",
          attrs: {
            "variant": "flat-dark",
            "disabled": !_vm.isEditing
          },
          on: {
            "click": function click($event) {
              return _vm.removeLineHandle(item);
            }
          }
        }, [_c('IAmIcon', {
          attrs: {
            "icon": "trash",
            "size": "18",
            "color": "#A30D11"
          }
        })], 1)], 1)];
      }
    }], null, true)
  }), _vm.isInvalidIncorrectInvoiceItems ? _c('div', {
    staticClass: "text-danger px-50 font-weight-bold"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.msg.invalidIncorrectInvoiceItems')) + " ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex-center justify-content-end mb-50 gap-1"
  }, [_c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "info",
      "size": "sm"
    },
    on: {
      "click": _vm.addLineHandle
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "increase",
      "color": "white",
      "size": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.addLine')) + " ")], 1)], 1), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "danger",
      "size": "sm"
    },
    on: {
      "click": _vm.clearAllLine
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash",
      "color": "white",
      "size": "18"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.btn.clearAll')) + " ")], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }