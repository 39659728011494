<template>
  <b-modal
    id="modal-sign-incorrect-invoice"
    size="lg"
    centered
    header-bg-variant="light-primary"
    no-close-on-backdrop
    no-close-on-esc
    body-class="p-1 modal-sign-incorrect-invoice"
    title-class="h2 font-weight-bolder text-dark"
    :title="$t('invoice.incorrectInvoices.signModal.title')"
    hide-footer
    hide-header-close
    @show="onShowHandle"
    @hide="onHideHandle"
  >
    <!-- <BAlert
      v-if="isProcessingReleaseBatch"
      show
      variant="danger"
      class="px-2 py-50"
    >
      {{ $t('invoice.releaseBatch.processingError') }}
    </BAlert> -->
    <!-- <div class="d-flex-center align-items-start flex-column gap-2 mb-50">
      <b-table
        ref="refSignAndReleaseBatchInvoiceListTable"
        style="max-height: 60vh"
        class="mb-0 position-relative"
        :sticky-header="true"
        :items="isBatch ? invoicesToSignAndRelease: [incorrectInvoices]"
        responsive
        primary-key="id"
        show-empty
        :empty-text="$t('noMatchingResult')"
        no-border-collapse
        small
        bordered
        :fields="invoicesToSignAndReleaseTableColumns"
      >
        <template
          v-for="column in invoicesToSignAndReleaseTableColumns"
          #[`head(${column.key})`]="data"
        >
          <span
            :key="column.key"
            class="text-nowrap"
          >
            {{ $t(`invoice.columns.${data.column}`) }}
          </span>
        </template>

        <template #cell(no)="data">
          <span class="font-weight-bold">{{ data.index + 1 }}</span>
        </template>

        <template #cell(symbol)="{ item }">
          {{ item.templateNumber }}{{ item.symbol }}
        </template>

        <template #cell(invNumber)="{ item }">
          <div class="text-dark fw-700">
            {{ item.invNumber }}
          </div>
        </template>

        <template #cell(createdAt)="{ item }">
          <div class="">
            {{ convertISODateTime(item.createdAt).date }}
          </div>
        </template>
      </b-table>

      <b v-if="invoicesNotToSignAndRelease?.length && isBatch">
        {{ $t('invoice.releaseBatch.invoiceCannotRelease') }} <b class="text-info fw-700">({{ invoicesNotToSignAndRelease?.length }})</b> -
        <span
          v-for="(groupByInvoiceNotToCancelItem, groupByInvoiceNotToCancelIndex) in groupBy(invoicesNotToSignAndRelease, 'reason')"
          :key="groupByInvoiceNotToCancelIndex"
        >
          <span
            v-for="(invoiceNotToCancelItem, invoiceNotToCancelIndex) in groupByInvoiceNotToCancelItem"
            :key="invoiceNotToCancelIndex"
          >
            <span class="fw-700 font-italic text-danger">{{ invoiceNotToCancelItem.invNumber || '&lt;Hoá đơn chưa có số&gt;' }}</span>
            <span v-if="invoiceNotToCancelIndex !== groupByInvoiceNotToCancelItem.length - 1">, </span>
            <span
              v-else
              class="font-weight-light font-italic"
            > ({{ $t(`invoice.msg.${invoiceNotToCancelItem.reason}`) }})</span>
          </span>

          <span v-if="groupByInvoiceNotToCancelIndex !== groupByInvoiceNotToCancelItem.length - 1"> ; </span>
        </span>
      </b>
    </div> -->

    <div>
      <b-form-group label-for="signType">
        <template #label>
          <b class="text-16px">
            {{ $t('invoice.incorrectInvoices.signModal.signType') }}
            <span class="text-danger">(*)</span>
          </b>
        </template>
        <v-select
          id="signType"
          v-model="signType"
          :name="$t('invoice.incorrectInvoices.signModal.signType')"
          :options="SIGN_TYPE_OPTIONS"
          label="label"
          :clearable="false"
          :reduce="(val) => val.value"
          :placeholder="$t('invoice.incorrectInvoices.signModal.signTypePh')"
        >
          <template #option="data">
            {{ $t(`invoice.signModal.${data.label}`) }}
          </template>
          <template #selected-option="data">
            {{ $t(`invoice.signModal.${data.label}`) }}
          </template>
          <template #no-options>
            {{ $t('noOptions') }}
          </template>
        </v-select>
      </b-form-group>

      <!-- ANCHOR: SIGN_TYPE.FILE -->
      <div v-if="!isSignTypeUSBToken">
        <b-table
          ref="refTable"
          :items="certificatesList"
          :fields="tableColumns"
          responsive
          small
          hover
          no-border-collapse
          show-empty
          :empty-text="$t('noMatchingResult')"
          :busy="isLoading"
          select-mode="single"
          selectable
          @row-clicked="onRowSelected"
        >
          <template
            v-for="column in tableColumns"
            #[`head(${column.key})`]
          >
            <div
              :key="column.key"
              class="text-dark d-flex-center"
            >
              {{ $t(`invoice.signModal.columns.${column.key}`) }}
            </div>
          </template>
          <template #table-busy>
            <div class="d-flex-center text-dark my-2 gap-2">
              <b-spinner class="align-middle" />
              <strong>{{ $t('loading') }}</strong>
            </div>
          </template>

          <template #cell(checkbox)="row">
            <b-form-checkbox
              :checked="row.item.serial === certificateSelected"
              @change="onRowSelected(row.item)"
            />
          </template>

          <template #cell(organizationName)="row">
            {{ row.item.organizationName }}
          </template>

          <template #cell(serial)="row">
            <code>
              {{ row.item.serial }}
            </code>
            <BBadge
              v-if="row.item?.isDefault"
              pill
              variant="success"
              class="px-1"
            >
              Mặc định
            </BBadge>
          </template>

          <template #cell(startDate)="row">
            {{ convertISODateTime(row.item.startDate).date }}
          </template>

          <template #cell(endDate)="row">
            {{ convertISODateTime(row.item.endDate).date }}
          </template>
        </b-table>
      </div>
      <!-- ANCHOR: SIGN_TYPE.USB -->
      <div v-else>
        <b-alert
          v-if="!isPluggingInUSB"
          show
          variant="warning"
          class="px-2 py-1"
        >
          Vui lòng kết nối USB Token và bật phần mềm đọc token!
        </b-alert>
        <div v-else>
          <b-table
            ref="refTable"
            :items="certificatesList"
            :fields="tableColumns"
            responsive
            small
            hover
            no-border-collapse
            sticky-header
            show-empty
            :empty-text="$t('noMatchingResult')"
            :busy="isLoading"
            select-mode="single"
            selectable
            @row-clicked="onRowSelected"
          >
            <template
              v-for="column in tableColumns"
              #[`head(${column.key})`]
            >
              <div
                :key="column.key"
                class="text-dark d-flex-center"
              >
                {{ $t(`invoice.signModal.columns.${column.key}`) }}
              </div>
            </template>
            <template #table-busy>
              <div class="d-flex-center text-dark my-2 gap-2">
                <b-spinner class="align-middle" />
                <strong>{{ $t('loading') }}</strong>
              </div>
            </template>

            <template #cell(checkbox)="row">
              <b-form-checkbox
                :checked="row.item.serial === certificateSelected"
                @change="onRowSelected(row.item)"
              />
            </template>

            <template #cell(organizationName)="row">
              {{ row.item?.Subject || row.item?.subject }}
            </template>

            <template #cell(serial)="row">
              <code>
                {{ row.item.serial }}
              </code>
            </template>

            <template #cell(startDate)="row">
              {{ convertISODateTime(row.item.startDate).date }}
            </template>

            <template #cell(endDate)="row">
              {{ convertISODateTime(row.item.endDate).date }}
            </template>
          </b-table>
        </div>
      </div>
    </div>

    <!-- ANCHOR footer button -->
    <div class="d-flex-between mt-1">
      <BButton
        variant="secondary"
        @click="onCloseHandle"
      >
        {{ $t('close') }}
      </BButton>
      <BButton
        variant="primary"
        :disabled="!certificateSelected"
        @click="onSubmitHandle"
      >
        <!-- :disabled="!certificateSelected || isProcessingReleaseBatch || (isBatch && !invoicesToSignAndRelease.length)" -->
        {{ $t('submit') }}
      </BButton>
    </div>
  </b-modal>
</template>

<script>
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import {
  BAlert,
  BButton,
  BFormCheckbox,
  BFormGroup,
  BModal,
  BSpinner,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import isNil from 'lodash/isNil'
import groupBy from 'lodash/groupBy'
import Vue from 'vue'

import {
  SIGN_TYPE_OPTIONS, SIGN_TYPE,
} from '@/constants/invoice'
import { convertISODateTime } from '@/@core/utils/filter'
import useInvoiceHandle from '@/views/invoices/useInvoiceHandle'
import { apiInvoice } from '@/api'

import useToast from '@useToast'

import { useIncorrectInvoiceSignModalHandle } from '../useIncorrectInvoiceHandle'

export default {
  components: {
    BModal,
    BButton,
    BFormGroup,
    BTable,
    BSpinner,
    BAlert,
    BFormCheckbox,
    BBadge,
    vSelect: () => import('vue-select'),
  },

  props: {
    companyId: {
      type: [Number, null],
      default: null,
    },
    incorrectInvoices: {
      type: [Array, null],
      default: () => [],
    },
    // isBatch: {
    //   type: Boolean,
    //   default: false,
    // },
    // checkedItems: {
    //   type: Array,
    //   default: () => [],
    // },
  },

  setup(props, { root, emit }) {
    const { companyId, incorrectInvoices } = toRefs(props)
    const { toastError, toastSuccess } = useToast()

    const signType = ref(null) //  FILE / USB
    const certificatesList = ref([]) // được hiển thị theo type
    const certificateSelected = ref() // CTS được chọn
    const allCertificateList = ref() // Danh sách tất cả CTS

    const tableColumns = ref([
      { key: 'checkbox', stickyColumn: true },
      { key: 'organizationName' },
      { key: 'serial' },
      { key: 'startDate' },
      { key: 'endDate' },
    ])

    const {
      getCompanyCertificates,
      loadingGetCompanyCertificates,
    } = useInvoiceHandle()

    const {
      signIncorrectInvoices, loadingSignModal, getIncorrectInvoicesXml, signCompleteIncorrectInvoices,
    } = useIncorrectInvoiceSignModalHandle()

    // const invoicesToSignAndRelease = ref([])
    // const invoicesNotToSignAndRelease = ref([])

    async function getCertificateListByFile() {
      certificatesList.value = allCertificateList.value.filter(cert => cert.type === SIGN_TYPE.FILE)
    }

    const isSignTypeUSBToken = computed(() => signType.value === SIGN_TYPE.USB)
    const isPluggingInUSB = ref(false)

    // const isProcessingReleaseBatch = ref(false)
    const intervalId = ref()

    // ANCHOR: USB handle
    async function handleUsbToken() {
      const certificatesTypeUSB = allCertificateList.value
        .filter(cert => cert.type === SIGN_TYPE.USB)
        .map(item => item.serial)

      async function pullingGetCertsByToken() {
        try {
          const res = await apiInvoice.getCertsByToken()
          certificatesList.value = res.data?.data.filter(item => certificatesTypeUSB.includes(item.serial)) ?? []
          if (certificatesList.value.length) {
            isPluggingInUSB.value = true
            clearInterval(intervalId.value)
          } else if (res.data?.data.length) {
            isPluggingInUSB.value = false
            toastError('Chứng thư số trong USB chưa được cài đặt. Vui lòng cài đặt chứng thư số có trong USB và thử lại')
            clearInterval(intervalId.value)
          }
        } catch (error) {
          isPluggingInUSB.value = false
          clearInterval(intervalId.value)
        }
      }
      intervalId.value = setInterval(pullingGetCertsByToken, 1000)
    }

    watch(signType, () => {
      if (signType.value === SIGN_TYPE.FILE) {
        getCertificateListByFile()
        if (intervalId.value) {
          clearInterval(intervalId.value)
        }
      }
      else if (signType.value === SIGN_TYPE.USB) {
        handleUsbToken()
      }
    }, { immediate: true })

    function onCloseHandle() {
      root.$bvModal.hide('modal-sign-incorrect-invoice')
    }

    async function onSubmitHandle() {
      // ANCHOR KÝ = USB
      if (isPluggingInUSB.value) {
        root.$bvModal.show('modal-api-loading')

        // Get XML
        const payloadGetXmlData = {
          externalIds: incorrectInvoices.value.map(item => item.externalId),
        }
        let incorrectInvoicesXmls
        try {
          incorrectInvoicesXmls = await getIncorrectInvoicesXml(payloadGetXmlData)
          console.log({ incorrectInvoicesXmls })
        } catch (error) {
          toastError('Lỗi lấy XML thông báo sai sót')
          root.$bvModal.hide('modal-api-loading')
          return
        }

        // có XML rồi thì gửi ký = USB TOKEN
        const payloadSignBatchUsb = {
          serial: certificateSelected.value,
          signableItems: incorrectInvoicesXmls.map(xml => ({
            id: xml.id,
            xmlData: xml.xmlData,
          })),
        }
        let signedIncorrectInvoicesResponse
        try {
          signedIncorrectInvoicesResponse = await apiInvoice.signBatchInvoiceLocal(payloadSignBatchUsb)
          console.log({ signedIncorrectInvoicesResponse })
        } catch (error) {
          toastError('Lỗi Ký TBSS bằng USB')
          root.$bvModal.hide('modal-api-loading')
          return
        }

        // update portal = sign-complete
        const signCompletePayload = {
          data: signedIncorrectInvoicesResponse
            ? signedIncorrectInvoicesResponse.data.data.map(item => ({
              externalId: item.invoiceId,
              signedData: item.signedData?.data ?? item.signedData,
            }))
            : payloadSignBatchUsb.invoiceItems.map(item => ({
              externalId: item.invoiceId,
              signedData: '',
            })),
        }

        const signCompleteResponse = await signCompleteIncorrectInvoices(signCompletePayload)
        console.log({ signCompleteResponse })

        if (signCompleteResponse && signedIncorrectInvoicesResponse) {
          toastSuccess('Ký TBSS bằng USB thành công')
        } else if (!signCompleteResponse && signedIncorrectInvoicesResponse) {
          toastError('Lỗi ký TBSS bằng USB')
        }
        onCloseHandle()
        root.$bvModal.hide('modal-api-loading')
      }
      // ANCHOR Ký = file
      else {
        root.$bvModal.show('modal-api-loading')
        try {
          const payload = {
            externalIds: incorrectInvoices.value.map(item => item.externalId),
            serial: certificateSelected.value,
          }
          await signIncorrectInvoices(payload)
          emit('refetch')
          onCloseHandle()
          root.$bvModal.hide('modal-api-loading')
        } catch (error) {
          toastError('invoice.msg.signAndReleaseError')
          root.$bvModal.hide('modal-api-loading')
        }
      }
    }

    async function onShowHandle() {
      intervalId.value = null
      isPluggingInUSB.value = null
      certificatesList.value = []

      // invoicesNotToSignAndRelease.value = props.checkedItems
      //   .filter(invoiceItem => {
      //     if (invoiceItem.releaseStatus !== RELEASE_STATUS.NEW
      //     || invoiceItem.invoiceStatus !== INVOICE_STATUS.NEW) {
      //       invoiceItem.reason = 'WRONG_STATUS'
      //       return true
      //     }
      //     if (checkGenerateInvoiceNumberOnCreationWithInvNumber(invoiceItem)) {
      //       invoiceItem.reason = 'DIFFERENCE_GENERATE_INVOICE_NUMBER_ON_CREATION'
      //       return true
      //     }
      //     return false
      //   })
      //   .map(invoiceItem => ({ invNumber: invoiceItem.invNumber, reason: invoiceItem.reason }))
      //   .sort((a, b) => a.invNumber > b.invNumber ? 1 : -1)
      // invoicesToSignAndRelease.value = props.checkedItems
      //   .filter(invoiceItem => invoiceItem.releaseStatus === RELEASE_STATUS.NEW
      //   && invoiceItem.invoiceStatus === INVOICE_STATUS.NEW
      //   && !checkGenerateInvoiceNumberOnCreationWithInvNumber(invoiceItem))

      // get certificates
      if (isNil(companyId.value)) {
        toastError('invoice.msg.companyNotFound')
        return
      }
      allCertificateList.value = await getCompanyCertificates(companyId.value)
      if (!allCertificateList.value.length) {
        Vue.swal({
          title: 'Cảnh báo!',
          text: 'Vui lòng thiết lập chứng thư số để phát hành.',
          icon: 'warning',
          showCancelButton: false,
          showConfirmButton: true,
          confirmButtonText: 'Đóng',
          customClass: {
            confirmButton: 'btn btn-outline-warning',
          },
          buttonsStyling: false,
        })
          .then(result => {
            if (result.value) {
              root.$bvModal.hide('modal-sign-incorrect-invoice')
            }
          })
      }
      const defaultCertificate = allCertificateList.value.find(item => item.isDefault)
      if (defaultCertificate) {
        signType.value = defaultCertificate?.type
        certificateSelected.value = defaultCertificate?.serial
      } else {
        signType.value = SIGN_TYPE.FILE
        certificateSelected.value = null
      }
    }

    // const invoicesToSignAndReleaseTableColumns = [
    //   { label: 'no', key: 'no' },
    //   { label: 'symbol', key: 'symbol' },
    //   { label: 'invNumber', key: 'invNumber' },
    //   { label: 'createdAt', key: 'createdAt' },
    // ]

    const refTable = ref()
    function onRowSelected(item) {
      if (certificateSelected.value === item?.serial) {
        certificateSelected.value = null
        return
      }
      certificateSelected.value = item?.serial ?? null
    }
    function onHideHandle() {
      signType.value = null
      clearInterval(intervalId.value)
    }

    const isLoading = computed(() => loadingGetCompanyCertificates.value || loadingSignModal.value)

    return {
      // invoicesToSignAndRelease,
      // invoicesNotToSignAndRelease,
      // invoicesToSignAndReleaseTableColumns,
      onSubmitHandle,
      onCloseHandle,
      onShowHandle,
      signType,
      SIGN_TYPE_OPTIONS,
      SIGN_TYPE,
      isSignTypeUSBToken,
      certificatesList,
      isLoading,
      tableColumns,
      convertISODateTime,
      onRowSelected,
      certificateSelected,
      refTable,
      isPluggingInUSB,
      onHideHandle,
      groupBy,
    }
  },
}
</script>

<style lang="scss">
  .modal-sign-incorrect-invoice {
    .table {
      thead th {
        vertical-align: middle;
      }

      &.b-table {
        >tbody {
          .b-table-row-selected {
            &.table-active {
              td {
                background-color: white;
                color: black;
              }
            }
          }
        }
      }
    }
  }
</style>
