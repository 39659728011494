import { ref, computed, watch } from '@vue/composition-api'

import { INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE } from '@/constants/invoice'
import store from '@/store'
// import invoiceStoreModule from '@/views/invoices/invoiceStoreModule'

import useToast from '@useToast'

export default function useInvoiceTicketHandle() {
  const {
    toastError,
    // toastSuccess
  } = useToast()

  // if (!store.hasModule(INVOICE_STORE)) store.registerModule(INVOICE_STORE, invoiceStoreModule)

  const refInvoiceTicketListTable = ref(null)
  const loading = ref(false)

  const tableColumnsInvoiceTicketList = ref([
    {
      label: 'checkbox', key: 'checkbox', stickyColumn: true, tdClass: 'bg-white',
    },
    { label: 'flightDate', key: 'flightDate', required: true },
    { label: 'bookingCode', key: 'bookingCode' },
    { label: 'ticketNumber', key: 'ticketNumber', required: true },
    { label: 'customerName', key: 'customerName' },
    { label: 'flightTrip', key: 'flightTrip' },
    { label: 'type', key: 'type', required: true },
    { label: 'isInvoiced', key: 'isInvoiced' },
    { label: 'createdAt', key: 'createdAt' },
    { label: 'totalAmount', key: 'totalAmount', required: true },
    { label: 'valueAddedTax', key: 'valueAddedTax', required: true },
    { label: 'collectionFee', key: 'collectionFee', required: true },
    {
      label: 'actions', key: 'actions', stickyColumn: true, class: 'position-right-0', tdClass: 'bg-white',
    },
  ])

  const invoiceTicketList = ref([])
  // filter
  const flightDateFrom = ref('') // ngày bay từ
  const flightDateTo = ref('') // đến ngày bay
  const bookingCode = ref('')
  const ticketNumber = ref('')
  const customerName = ref('')
  const invoiceTicketType = ref(null)
  const isInvoicedFilter = ref(null) // trạng thái lập hoá đơn

  const sizePerPage = ref(10)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalInvoiceTickets = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceTicketListTable.value ? refInvoiceTicketListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoiceTickets.value,
    }
  })

  const refetchData = () => {
    refInvoiceTicketListTable.value.refresh()
  }

  const clearFilter = () => {
    flightDateFrom.value = ''
    flightDateTo.value = ''
    bookingCode.value = ''
    ticketNumber.value = ''
    customerName.value = ''
    invoiceTicketType.value = null
    isInvoicedFilter.value = null
    refetchData()
  }

  const getInvoiceTickets = (_ctx, callback, isInvoice = false, includeInvoiceId = null) => {
    loading.value = true
    const payload = {
      flightDateFrom: flightDateFrom.value || undefined,
      flightDateTo: flightDateTo.value || undefined,
      bookingCode: bookingCode.value || undefined,
      ticketNumber: ticketNumber.value || undefined,
      customerName: customerName.value || undefined,
      type: invoiceTicketType.value ?? undefined,
      isInvoiced: isInvoice ? false : (isInvoicedFilter.value ?? undefined),
      includeInvoiceId: includeInvoiceId ?? undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      size: sizePerPage.value,
      page: currentPage.value,
    }
    store
      .dispatch('app-invoice/getInvoiceTickets', payload)
      .then(res => {
        totalInvoiceTickets.value = res.data.total
        invoiceTicketList.value = res.data.items.map(item => ({ ...item, isInvoiceTicket: true }))
        callback(invoiceTicketList.value)
      })
      .catch(error => {
        console.error('error', error)
        toastError('Lỗi tải danh sách vé ngoài hệ thống')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )

  return {
    refInvoiceTicketListTable,
    getInvoiceTickets,
    loading,
    refetchData,
    clearFilter,
    dataMeta,
    sizePerPage,
    currentPage,
    totalInvoiceTickets,
    isSortDirDesc,
    sortBy,
    flightDateFrom,
    flightDateTo,
    bookingCode,
    ticketNumber,
    customerName,
    invoiceTicketType,
    isInvoicedFilter,
    INVOICE_STORE,
    tableColumnsInvoiceTicketList,
  }
}

export const INVOICE_TICKET_TYPE_OPTIONS = [
  { label: 'AIRLINE_TICKET', value: 'AIRLINE_TICKET', color: 'success' },
  { label: 'TRAIN_TICKET', value: 'TRAIN_TICKET', color: 'info' },
  { label: 'TOUR_TICKET', value: 'TOUR_TICKET', color: 'warning' },
  { label: 'OTHER', value: 'OTHER', color: 'dark' },
]
export const INVOICE_TICKET_STATUS = INVOICE_TICKET_TYPE_OPTIONS.reduce((a, c) => ({ ...a, [c.value]: c.label }), {})
export const resolveColorOfInvoiceTicketType = type => INVOICE_TICKET_TYPE_OPTIONS.find(item => item.value === type)?.color

export const isInvoicedFilterOptions = [
  { label: 'ALL', value: null, color: 'secondary' },
  { label: 'INVOICED', value: true, color: 'light-success' },
  { label: 'UNINVOICED', value: false, color: 'light-danger' },
]

export const resolveIsInvoicedColor = (type, field = 'label') => isInvoicedFilterOptions.find(item => item[field] === type)?.color
export const resolveIsInvoiced = (type, field = 'label') => isInvoicedFilterOptions.find(item => item[field] === type)
