import {
  ref, computed, watch, getCurrentInstance,
} from '@vue/composition-api'

import {
  INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE,
  INCORRECT_INVOICE_APP_STORE_MODULE_NAME as INCORRECT_INVOICE_STORE,
} from '@/constants/invoice'
import store from '@/store'
import { arrayToObject, useRouter } from '@/@core/utils/utils'
import { convertISODateTime } from '@/@core/utils/filter'

import useToast from '@useToast'

export const INCORRECT_INVOICES_LIST_COLUMNS = [
  { key: 'checkbox', stickyColumn: true, tdClass: 'bg-white width-30' },
  // { key: 'no' }, // STT
  { key: 'notificationNumber' }, // Số thông báo
  { key: 'createdAt' }, // Ngày thông báo
  { key: 'notificationType' }, // Loại thông báo
  { key: 'status' }, // Trạng thái thông báo
  { key: 'statusOfTaxAuthority' }, // Trạng thái CQT
  { key: 'notificationNumberOfTaxAuthority' }, // Số thông báo của CQT
  { key: 'notificationDateOfTaxAuthority' }, // Ngày thông báo của CQT
  { key: 'action' },
]

export default function useIncorrectInvoiceHandle() {
  const { toastError, toastSuccess } = useToast()
  const vm = getCurrentInstance().proxy

  const refIncorrectInvoiceListTable = ref(null)
  const loading = ref(false)

  const tableColumnIncorrectInvoiceList = ref(INCORRECT_INVOICES_LIST_COLUMNS)

  // filter
  const startDate = ref('') // từ ngày
  const endDate = ref('') // đến ngày
  const notificationType = ref('') // Loại thông báo
  const notificationStatus = ref('') // trạng thái thông báo
  const statusOfTaxAuthority = ref('') // trạng thái phát hành CQT
  const notificationNumberFilter = ref('') // Tìm kiếm theo số thông báo

  const sizePerPage = ref(20)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalIncorrectInvoices = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refIncorrectInvoiceListTable.value ? refIncorrectInvoiceListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalIncorrectInvoices.value,
    }
  })

  const clearFilter = () => {
    startDate.value = ''
    endDate.value = ''
    notificationType.value = ''
    notificationStatus.value = ''
    statusOfTaxAuthority.value = ''
  }

  const refetchData = () => {
    refIncorrectInvoiceListTable.value.refresh()
  }

  const fetchIncorrectInvoices = (_ctx, callback) => {
    loading.value = true
    const payload = {
      startDate: startDate.value || undefined,
      endDate: endDate.value || undefined,
      notificationType: notificationType.value || undefined,
      status: notificationStatus.value || undefined,
      statusOfTaxAuthority: statusOfTaxAuthority.value || undefined,
      notificationNumber: notificationNumberFilter.value || undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      size: sizePerPage.value,
      page: currentPage.value,
    }
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/fetchIncorrectInvoices`, payload)
      .then(res => {
        totalIncorrectInvoices.value = res.count
        const mappingRes = res.items
        // .map(item => ({
        //   ...item,
        //   invoiceItems: (item?.invoiceItems || []).filter(item => item?.itemType === GOODS_SERVICE_TYPES.GOODS_SERVICE),
        // }))
        callback(mappingRes)
      })
      .catch(error => {
        console.error('error', error)
        toastError('Lỗi tải danh sách hoá đơn sai sót')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc],
    () => {
      refetchData()
    },
  )

  const exportIncorrectInvoices = () => new Promise((resolve, reject) => {
    vm.$bvModal.show('modal-api-loading')
    const payload = {
      startDate: startDate.value || undefined,
      endDate: endDate.value || undefined,
      notificationType: notificationType.value || undefined,
      status: notificationStatus.value || undefined,
      statusOfTaxAuthority: statusOfTaxAuthority.value || undefined,
      notificationNumber: notificationNumberFilter.value || undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      size: sizePerPage.value,
      page: currentPage.value,
    }
    const fileName = `DanhSachThongBaoSaiSot_${convertISODateTime(new Date()).date.replaceAll('/', '')}`
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/exportIncorrectInvoice`, { payload, fileName })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.exportSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.exportError',
        })
        reject(e)
      })
      .finally(() => {
        vm.$bvModal.hide('modal-api-loading')
      })
  })

  return {
    refIncorrectInvoiceListTable,
    fetchIncorrectInvoices,
    loading,
    refetchData,
    clearFilter,
    dataMeta,
    sizePerPage,
    currentPage,
    totalIncorrectInvoices,
    isSortDirDesc,
    sortBy,
    startDate,
    endDate,
    notificationType,
    notificationStatus,
    statusOfTaxAuthority,
    notificationNumberFilter,
    INCORRECT_INVOICE_STORE,
    INVOICE_STORE,
    tableColumnIncorrectInvoiceList,
    exportIncorrectInvoices,
  }
}

const getVariant = (array, value) => array.find(item => item.value === value)?.variant ?? 'secondary'

// Loại TB-HĐSS
export const INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS = [
  { label: 'TYPE_1', value: 'TYPE_1', variant: 'light-success' }, // Thông báo của NNT
  { label: 'TYPE_2', value: 'TYPE_2', variant: 'light-info' }, // Thông báo của NNT theo thông báo rà soát của CQT
]
export const INCORRECT_INVOICE_NOTIFICATION_TYPE = arrayToObject(INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS)

export const INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS = [
  { label: 'NEW', value: 'NEW', variant: 'light-success' }, // Mới tạo
  { label: 'SIGNED', value: 'SIGNED', variant: 'light-warning' }, // Đã ký
]

export const INCORRECT_INVOICE_NOTIFICATION_STATUS = arrayToObject(INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS)

export const INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS = [
  { label: 'NOT_YET_SENT', value: 'NOT_YET_SENT', variant: 'secondary' }, // Chưa gửi CQT
  { label: 'SENT', value: 'SENT', variant: 'light-warning' }, // Đã gửi CQT
  { label: 'IINO_VALID', value: 'IINO_VALID', variant: 'light-success' }, // Hợp lệ
  { label: 'IINO_RECEIVED_AND_RETURNING_RESULTS', value: 'IINO_RECEIVED_AND_RETURNING_RESULTS', variant: 'success' }, // Tiếp nhận
  { label: 'IINO_INVALID', value: 'IINO_INVALID', variant: 'light-danger' }, // Không hợp lệ
]

export const INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY = arrayToObject(INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS)

export const resolveNotificationTypeVariant = value => getVariant(INCORRECT_INVOICE_NOTIFICATION_TYPE_OPTIONS, value)
export const resolveNotificationStatusVariant = value => getVariant(INCORRECT_INVOICE_NOTIFICATION_STATUS_OPTIONS, value)
export const resolveNotificationStatusOfTaxAuthorityVariant = value => getVariant(INCORRECT_INVOICE_NOTIFICATION_STATUS_OF_TAX_AUTHORITY_OPTIONS, value)

// Hình thức xử lý HĐ bị sai sót
export const INCORRECT_INVOICE_ACTION_OPTIONS = [
  { label: 'CANCEL', value: 'CANCEL' },
  { label: 'EDIT', value: 'EDIT' },
  { label: 'REPLACE', value: 'REPLACE' },
  { label: 'EXPLANATION', value: 'EXPLANATION' },
]

export const INCORRECT_INVOICE_ACTION = arrayToObject(INCORRECT_INVOICE_ACTION_OPTIONS)

// Loại HĐ Điện tử áp dụng
export const TYPE_OF_INVOICE_APPLICATION_OPTIONS = [
  { label: 'TYPE_1', value: 'TYPE_1' }, // HĐĐT theo NĐ 123/2020/NĐ-CP không mã của CQT
  { label: 'TYPE_2', value: 'TYPE_2' }, // HĐĐT theo NĐ 123/2020/NĐ-CP có mã của CQT
  { label: 'TYPE_3', value: 'TYPE_3' }, // HĐĐT theo NĐ 51/2010/NĐ-CP
]
export const TYPE_OF_INVOICE_APPLICATION = arrayToObject(TYPE_OF_INVOICE_APPLICATION_OPTIONS)

export function useIncorrectInvoiceDetailHandle() {
  const { toastError, toastSuccess } = useToast()

  const loadingDetail = ref(false)

  const getIncorrectInvoiceById = id => new Promise((resolve, reject) => {
    loadingDetail.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/getIncorrectInvoiceById`, id)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingDetail.value = false
      })
  })

  const updateIncorrectInvoice = (incorrectInvoiceId, payload) => new Promise((resolve, reject) => {
    loadingDetail.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/updateIncorrectInvoice`, { id: incorrectInvoiceId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.updateInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.updateInvoiceError',
        })
        reject(e)
      })
      .finally(() => {
        loadingDetail.value = false
      })
  })

  const getIncorrectInvoicePdfById = id => new Promise((resolve, reject) => {
    loadingDetail.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/getIncorrectInvoicePdfById`, id)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: `${e.message || e}`,
        })
        reject(e)
      })
      .finally(() => {
        loadingDetail.value = false
      })
  })
  return {
    getIncorrectInvoiceById,
    updateIncorrectInvoice,
    getIncorrectInvoicePdfById,
    loadingDetail,
  }
}

export function useIncorrectInvoiceCreateHandle() {
  const { toastError, toastSuccess } = useToast()
  const { router } = useRouter()
  const loadingCreate = ref(false)

  const createIncorrectInvoice = payload => {
    loadingCreate.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/createIncorrectInvoice`, payload)
      .then(res => {
        toastSuccess('invoice.incorrectInvoices.msg.createSuccess')

        if (res?.id) {
          router.push({ name: 'apps-incorrectInvoices-list' })
        }
      })
      .catch(error => {
        console.error({ error })
        toastError('invoice.incorrectInvoices.msg.createError')
      })
      .finally(() => {
        loadingCreate.value = false
      })
  }
  return {
    createIncorrectInvoice,
    loadingCreate,
  }
}

export function useIncorrectInvoiceSignModalHandle() {
  const { toastError, toastSuccess } = useToast()

  const loadingSignModal = ref(false)
  const signIncorrectInvoices = payload => new Promise((resolve, reject) => {
    loadingSignModal.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/signIncorrectInvoices`, payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.incorrectInvoices.msg.signSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.incorrectInvoices.msg.signError',
        })
        reject(e)
      })
      .finally(() => {
        loadingSignModal.value = false
      })
  })

  const releaseIncorrectInvoices = (payload, refetchCallback = () => {}) => new Promise((resolve, reject) => {
    loadingSignModal.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/releaseIncorrectInvoices`, payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.incorrectInvoices.msg.releaseSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.incorrectInvoices.msg.releaseError',
        })
        reject(e)
      })
      .finally(() => {
        refetchCallback()
        loadingSignModal.value = false
      })
  })

  const vm = getCurrentInstance().proxy
  function releaseIncorrectInvoicesHandle(incorrectInvoices, refetchCallback = () => {}) {
    const titleMsg = () => vm.$createElement('div', {
      domProps: {
        innerHTML:
          `Bạn muốn gửi thông báo hóa đơn sai sót <em class='fw-700'>${
            incorrectInvoices
              .map(incorrectInvoice => incorrectInvoice?.notificationNumber)
              .join(', ')
          }</em> tới cơ quan thuế? ?`,
      },
    })
    vm.$bvModal
      .msgBoxConfirm(titleMsg(), {
        title: vm.$t('modal.confirm'),
        size: 'sm',
        okVariant: 'info',
        okTitle: 'Gửi',
        cancelTitle: 'Huỷ',
        cancelVariant: 'outline-danger',
        hideHeaderClose: true,
        centered: true,
      })
      .then(value => {
        if (value) {
          const payload = {
            externalIds: incorrectInvoices.map(incorrectInvoice => incorrectInvoice?.externalId),
          }
          releaseIncorrectInvoices(payload, refetchCallback)
        }
      })
  }

  const getIncorrectInvoicesXml = payload => new Promise((resolve, reject) => {
    loadingSignModal.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/getIncorrectInvoicesXml`, payload)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loadingSignModal.value = false
      })
  })

  const signCompleteIncorrectInvoices = payload => new Promise((resolve, reject) => {
    loadingSignModal.value = true
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/signCompleteIncorrectInvoices`, payload)
      .then(res => {
        resolve(res)
      })
      .catch(e => {
        reject(e)
      })
      .finally(() => {
        loadingSignModal.value = false
      })
  })
  return {
    signIncorrectInvoices,
    loadingSignModal,
    releaseIncorrectInvoicesHandle,
    getIncorrectInvoicesXml,
    signCompleteIncorrectInvoices,
  }
}

export function useSendIncorrectInvoiceEmailsHandle() {
  const { toastError, toastSuccess } = useToast()
  const vm = getCurrentInstance().proxy

  const sendIncorrectInvoiceEmails = payload => new Promise((resolve, reject) => {
    vm.$bvModal.show('modal-api-loading')
    store
      .dispatch(`${INCORRECT_INVOICE_STORE}/sendIncorrectInvoiceEmails`, payload)
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.incorrectInvoices.msg.sendMailsSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.incorrectInvoices.msg.sendMailsError',
        })
        reject(e)
      })
      .finally(() => {
        vm.$bvModal.hide('modal-api-loading')
      })
  })
  return {
    sendIncorrectInvoiceEmails,
  }
}
