<template lang="">
  <BCard
    id="incorrect-invoice-detail-card"
    body-class="p-50"
  >
    <div class="d-flex-center justify-content-start gap-1 bg-primary-gradient">
      <IAmIcon
        icon="infoCircleOutline"
        size="25"
        class="p-1 icon-card"
      />
      <span class="font-medium-3 m-0 fw-700">
        {{ $t('invoice.incorrectInvoices.detailCard.title') }}
      </span>
    </div>

    <!-- SECTION Table Chi tiết hoá đơn -->
    <!-- :class="`position-relative mb-50 ${isInvalidInvoiceItems ? 'border-danger rounded' : ''}`" -->
    <b-table
      :style="`max-height: 800px; ${!isEditing ? 'background-color: #f8f8f8' : ''}`"
      :sticky-header="true"
      :class="`position-relative my-50 ${isInvalidIncorrectInvoiceItems ? 'border-danger rounded' : ''}`"
      :items="selectedInvoices"
      responsive
      primary-key="id"
      show-empty
      :empty-text="$t('invoice.incorrectInvoices.msg.pleaseChooseInvoices')"
      no-border-collapse
      small
      bordered
      :tbody-tr-class="{'bg-disabled': !isEditing}"
      :fields="tableColumnIncorrectInvoices"
    >
      <template
        v-for="column in tableColumnIncorrectInvoices"
        #[`head(${column.key})`]="data"
      >
        <Stack
          :key="column.key"
          center
          :class="`text-dark ${column.thClass ?? 'text-nowrap'} d-flex-center`"
        >
          {{ $t(`invoice.incorrectInvoices.columns.${data.column}`) }}
        </Stack>
      </template>

      <template #empty="scope">
        <b-alert
          show
          variant="warning"
          class="px-2 py-1 mb-0"
        >
          {{ scope.emptyText }}
        </b-alert>
      </template>

      <!-- ANCHOR STT -->
      <template #cell(no)="row">
        <div class="text-right">
          {{ row.index + 1 }}
        </div>
      </template>

      <!-- ANCHOR Mẫu số/ ký hiệu -->
      <template #cell(templateNumberAndSymbol)="{ item }">
        <ValidationProvider
          #default="{ errors }"
          :name="$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')"
          rules="required"
          :vid="`templateNumberAndSymbol-${item.id}`"
        >
          <BFormGroup :class="{'is-invalid': errors[0]}">
            <BFormInput
              :id="`templateNumberAndSymbol-${item.id}`"
              v-model="item.templateNumberAndSymbol"
              :disabled="!isEditing"
              style="overflow: auto"
              :name="$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')"
              :state="errors[0] ? false : null"
              :placeholder="$t('invoice.incorrectInvoices.columns.templateNumberAndSymbol')"
              :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
            />
            <b-tooltip
              v-if="errors[0]"
              variant="danger"
              boundary="window"
              :target="`templateNumberAndSymbol-${item.id}`"
              triggers="hover"
            >
              {{ errors[0] }}
            </b-tooltip>
          </BFormGroup>
        </ValidationProvider>
      </template>

      <!-- ANCHOR Số hoá đơn  -->
      <template #cell(invNumber)="{ item }">
        <div class="width-120">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.incorrectInvoices.columns.invNumber')"
            :vid="`invNumber-${item.id}`"
            :rules="`required|isUniqueTicketNumber:${selectedInvoices.map(i => i?.invoiceNumber)}|validTicketNumber:${invalidInvoiceNumbers}`"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :id="`invNumber-${item.id}`"
                v-model="item.invoiceNumber"
                :disabled="!isEditing"
                style="overflow: auto"
                :name="$t('invoice.incorrectInvoices.columns.invNumber')"
                :state="errors[0] ? false : null"
                :placeholder="$t('invoice.incorrectInvoices.columns.invNumber')"
                :class="`fw-700 ${!errors[0] ? 'border-transparent' : ''}`"
                @input="val => onInvoiceNumberChange(val, item)"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`invNumber-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Tổng tiền thanh toán -->
      <template #cell(amount)="{ item }">
        <div class="width-120">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.incorrectInvoices.columns.amount')"
            rules="required|min_value:0"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :value="item.amount"
                class="d-none"
                disabled
                :name="$t('invoice.incorrectInvoices.columns.amount')"
              />
              <IAmInputMoney
                :id="`amount-${item.id}`"
                :value-money.sync="item.amount"
                :disabled="!isEditing"
                :canInputNegative="true"
                :custom-class="`${!errors[0] ? 'border-transparent' : ''}`"
                :state="errors[0] ? false : null"
                :name="$t('invoice.incorrectInvoices.columns.amount')"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`amount-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Ngày lập hoá đơn -->
      <template #cell(invoiceDate)="{ item }">
        <div class="width-100">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.incorrectInvoices.columns.invoiceDate')"
            rules="required"
            :vid="`invoiceDate-${item.id}`"
          >
            <BFormGroup
              :id="`form-group-invoiceDate-${item.id}`"
              label-for="dateOfIssuance"
            >
              <BFormInput
                :value="item.invoiceDate"
                class="d-none"
              />
              <div :class="`form-control ${errors[0] ? 'is-invalid' : 'border-transparent'} p-0`">
                <flat-pickr
                  :id="`invoiceDate-${item.id}`"
                  v-model="item.invoiceDate"
                  class=""
                  :name="$t('invoice.incorrectInvoices.columns.invoiceDate')"
                  :disabled="!isEditing"
                  :placeholder="`${$t('invoice.incorrectInvoices.columns.invoiceDate')}`"
                  :config="{
                    allowInput: true,
                    dateFormat: 'Y-m-d',
                    altFormat: 'd/m/Y',
                    altInput: true,
                    maxDate: today,
                    locale: $i18n.locale === 'vi' ? Vietnamese : null,
                    disableMobile: true,
                    altInputClass: 'form-control input px-50 py-0 border-transparent height-35px',
                  }"
                />
              </div>

              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`form-group-invoiceDate-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Mã CQT cấp -->
      <template #cell(taxAuthorityCode)="{ item }">
        <div class="width-120">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.incorrectInvoices.columns.taxAuthorityCode')"
            :rules="`required`"
            :vid="`taxAuthorityCode-${item.id}`"
          >
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <BFormInput
                :id="`taxAuthorityCode-${item.id}`"
                v-model="item.taxAuthorityCode"
                :state="errors[0] ? false : null"
                :disabled="!isEditing"
                :formatter="value => value.trim()"
                :name="$t('invoice.incorrectInvoices.columns.taxAuthorityCode')"
                :class="{ 'border-transparent': !errors[0] }"
                :placeholder="$t('invoice.incorrectInvoices.ph.taxAuthorityCode')"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`taxAuthorityCode-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Loại áp dụng HĐĐT -->
      <template #cell(typeOfInvoiceApplication)="{ item }">
        <div class="custom-v-select-type-of-invoice-application width-150">
          <BFormGroup>
            <v-select
              v-model="item.typeOfInvoiceApplication"
              :options="TYPE_OF_INVOICE_APPLICATION_OPTIONS"
              :clearable="false"
              class="border-transparent w-100"
              append-to-body
              :calculate-position="(dropdownList, component) => withPopper(dropdownList, component, { width: '450px' })"
              label="label"
              :disabled="!isEditing"
              :reduce="val => val.value"
            >
              <template #option="data">
                <span>
                  {{ $t(`invoice.incorrectInvoices.typeOfInvoiceApplication.${data.label}`) }}
                </span>
              </template>

              <template #selected-option="data">
                <span>
                  {{ $t(`invoice.incorrectInvoices.typeOfInvoiceApplication.${data.label}`) }}

                </span>
              </template>

              <template #no-options>
                {{ $t('selectOptions.noOptions') }}
              </template>
            </v-select>
          </BFormGroup>
        </div>
      </template>

      <!-- ANCHOR Hình thức xử lý -->
      <template #cell(incorrectInvoiceAction)="{ item }">
        <div
          style="width: 150px;"
          class="custom-v-select-incorrect-invoice-action"
        >
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.incorrectInvoices.columns.incorrectInvoiceAction')"
            rules="required"
            :vid="`incorrectInvoiceAction-${item.id}`"
          >
            <BFormGroup :class="{ 'is-invalid': errors[0] }">
              <v-select
                :id="`incorrectInvoiceAction-${item.id}`"
                v-model="item.action"
                :name="$t('invoice.incorrectInvoices.columns.incorrectInvoiceAction')"
                :options="INCORRECT_INVOICE_ACTION_OPTIONS"
                :clearable="true"
                class="w-100"
                label="label"
                :disabled="!isEditing"
                :reduce="val => val.value"
                style="font-size: 1rem;"
                append-to-body
                :calculate-position="(dropdownList, component) => withPopper(dropdownList, component, { width: '150px' })"
                :placeholder="$t('invoice.incorrectInvoices.ph.incorrectInvoiceAction')"
              >
                <template #option="data">
                  <span>
                    {{ $t(`invoice.incorrectInvoices.incorrectInvoiceAction.${data.label}`) }}
                  </span>
                </template>
                <template #selected-option="data">
                  <span>
                    {{ $t(`invoice.incorrectInvoices.incorrectInvoiceAction.${data.label}`) }}
                  </span>
                </template>
                <template #no-options>
                  {{ $t('selectOptions.noOptions') }}
                </template>
              </v-select>
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`incorrectInvoiceAction-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR Lý do -->
      <template #cell(reason)="{ item }">
        <div class="width-150">
          <ValidationProvider
            #default="{ errors }"
            :name="$t('invoice.incorrectInvoices.columns.reason')"
            :vid="`reason-${item.id}`"
            rules="required"
          >
            <BFormInput
              :value="item.reason"
              class="d-none"
            />
            <BFormGroup :class="{'is-invalid': errors[0]}">
              <IAmInputTextOrTextarea
                :id="`reason-${item.id}`"
                :text.sync="item.reason"
                :state="errors[0] ? false : null"
                :disabled="!isEditing"
                type="text"
                :name="$t('invoice.incorrectInvoices.columns.reason')"
                :class="{
                  'border-transparent': !errors[0],
                }"
                rows="1"
                max-rows="4"
                :placeholder="$t('invoice.incorrectInvoices.ph.reason')"
              />
              <b-tooltip
                v-if="errors[0]"
                variant="danger"
                boundary="window"
                :target="`reason-${item.id}`"
                triggers="hover"
              >
                {{ errors[0] }}
              </b-tooltip>
            </BFormGroup>
          </ValidationProvider>
        </div>
      </template>

      <!-- ANCHOR thao tác -->
      <template #cell(actionNoTitle)="{ item }">
        <HStack>
          <BButton
            variant="flat-dark"
            class="p-50 rounded-circle"
            :disabled="!isEditing"
            @click="removeLineHandle(item)"
          >
            <IAmIcon
              icon="trash"
              size="18"
              color="#A30D11"
            />
          </BButton>
        </HStack>
      </template>
    </b-table>
    <div
      v-if="isInvalidIncorrectInvoiceItems"
      class="text-danger px-50 font-weight-bold"
    >
      {{ $t('invoice.msg.invalidIncorrectInvoiceItems') }}
    </div>
    <div class="d-flex-center justify-content-end mb-50 gap-1">
      <BButton
        variant="info"
        class="p-50"
        size="sm"
        @click="addLineHandle"
      >
        <HStack center>
          <IAmIcon
            icon="increase"
            color="white"
            size="18"
          />
          {{ $t('invoice.btn.addLine') }}
        </HStack>
      </BButton>

      <BButton
        variant="danger"
        class="p-50"
        size="sm"
        @click="clearAllLine"
      >
        <HStack center>
          <IAmIcon
            icon="trash"
            color="white"
            size="18"
          />
          {{ $t('invoice.btn.clearAll') }}
        </HStack>
      </BButton>
    </div>
    <!-- !SECTION -->
  </BCard>
</template>
<script>
import {
  BCard,
  BFormInput,
  BButton,
  BTable,
  BAlert,
  BFormGroup,
  BTooltip,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { createPopper } from '@popperjs/core'
import { ValidationProvider } from 'vee-validate'
import {
  computed, ref, toRefs, watch,
} from '@vue/composition-api'
import { cloneDeep, debounce } from 'lodash-es'
import { v4 as uuidv4 } from 'uuid'
import flatPickr from 'vue-flatpickr-component'
import { Vietnamese } from 'flatpickr/dist/l10n/vn'

import { formatCurrency, convertISODateTime, getToday } from '@/@core/utils/filter'
import store from '@/store'
import { INCORRECT_INVOICE_APP_STORE_MODULE_NAME as INCORRECT_INVOICE_STORE } from '@/constants/invoice'
import { apiInvoice } from '@/api'
import { numberToFixed } from '@/@core/utils/numberUtils'

import { onlyUpperCaseFormatter } from '@core/comp-functions/forms/formatter-input'

import { isUniqueTicketNumber, minValue } from '@validations'

import {
  INCORRECT_INVOICE_ACTION_OPTIONS,
  TYPE_OF_INVOICE_APPLICATION,
  TYPE_OF_INVOICE_APPLICATION_OPTIONS,
} from '../useIncorrectInvoiceHandle'

export default {
  components: {
    BCard,
    BFormInput,
    BButton,
    BTable,
    BAlert,
    BFormGroup,
    vSelect,
    ValidationProvider,
    BTooltip,
    flatPickr,
    IAmInputMoney: () => import('@/components/IAmInputMoney.vue'),
    IAmInputTextOrTextarea: () => import('@/components/IAmInputTextOrTextarea.vue'),
  },
  props: {
    incorrectInvoiceData: {
      type: Object,
      default: () => { },
    },
    isCreate: {
      type: Boolean,
      default: false,
    },
    isEditing: {
      type: Boolean,
      default: true,
    },
    isInvalidIncorrectInvoiceItems: {
      type: Boolean,
      default: false,
    },
    resetIncorrectInvoices: {
      type: Object,
      default: () => { },
    },
  },
  setup(props) {
    const { resetIncorrectInvoices, incorrectInvoiceData, isCreate } = toRefs(props)
    const selectedInvoices = computed(() => store.getters[`${INCORRECT_INVOICE_STORE}/getSelectedInvoices`])

    const blankLine = {
      id: null,
      typeOfInvoiceApplication: TYPE_OF_INVOICE_APPLICATION.TYPE_1, // Loại HĐ Điện tử áp dụng
      taxAuthorityCode: '',
      templateNumberAndSymbol: '',
      invoiceNumber: '',
      invoiceDate: convertISODateTime(new Date()).dateFilter,
      action: '',
      reason: '',
      amount: '',
      isInternal: true,
      incorrectInvoiceNotificationId: null,
      invoiceEmail: '',
      invoiceBuyerName: '',
      invoiceTotalAmount: '', // portal đang ko dùng field này
      invoiceBuyerUnitName: '',
    }

    const tableColumnIncorrectInvoices = computed(() => [
      { key: 'no' },
      { key: 'templateNumberAndSymbol', thClass: 'text-center text-wrap width-80' }, // Mẫu số/ Ký hiệu
      { key: 'invNumber', stickyColumn: true, tdClass: `${props.isEditing ? 'bg-white' : ''}` }, // Số hoá đơn
      { key: 'amount', thClass: 'text-center text-wrap' }, // Tổng tiền thanh toán (VND)
      { key: 'invoiceDate', thClass: 'text-center text-wrap' }, // Ngày lập hoá đơn
      { key: 'taxAuthorityCode' }, // Mã CQT cấp
      { key: 'typeOfInvoiceApplication' }, // Loại áp dụng HĐĐT
      { key: 'incorrectInvoiceAction' }, // Hình thức xử lý
      { key: 'reason' }, // Lý do
      { key: 'actionNoTitle', stickyColumn: true, class: 'position-right-0' },
    ])

    async function clearAllLine() {
      await store.dispatch(`${INCORRECT_INVOICE_STORE}/clearSelectedInvoices`)
    }

    function addLineHandle() {
      store.dispatch(`${INCORRECT_INVOICE_STORE}/addSelectedInvoice`, cloneDeep({ ...blankLine, id: uuidv4() }))
    }

    async function removeLineHandle(invoiceItem) {
      await store.dispatch(`${INCORRECT_INVOICE_STORE}/removeSelectedInvoice`, invoiceItem)
    }

    function initSelectedInvoices() {
      if (incorrectInvoiceData.value && !isCreate.value) {
        const incorrectInvoiceItems = incorrectInvoiceData.value.invoiceIncorrectItems
          .map(incorrectInvItem => {
            const mappingItem = {
              ...incorrectInvItem,
              // id: incorrectInvItem?.externalId,
              // typeOfInvoiceApplication: TYPE_OF_INVOICE_APPLICATION.TYPE_1, // Loại HĐ Điện tử áp dụng
              // taxAuthorityCode: incorrectInvItem?.releasedInvCode ?? '',
              // templateNumberAndSymbol: `${incorrectInvItem?.templateNumber}${incorrectInvItem?.symbol}`,
              // invoiceNumber: incorrectInvItem?.invNumber,
              invoiceDate: convertISODateTime(incorrectInvItem?.invoiceDate).dateFilter,
              // action: null,
              // reason: '',
              amount: numberToFixed(incorrectInvItem?.amount),
              // isInternal: true,
              // incorrectInvoiceNotificationId: null,
              // invoiceEmail: incorrectInvItem?.buyerEmail,
              // invoiceBuyerName: incorrectInvItem?.buyerName,
              invoiceTotalAmount: numberToFixed(incorrectInvItem?.invoiceTotalAmount), // portal đang ko dùng field này
              // invoiceBuyerUnitName: incorrectInvItem?.buyerUnitName,
            }
            return mappingItem
          })
        store.dispatch(`${INCORRECT_INVOICE_STORE}/setSelectedInvoicesInDetail`, incorrectInvoiceItems)
      } else {
        clearAllLine()
      }
    }

    watch(resetIncorrectInvoices.value, () => {
      initSelectedInvoices()
    }, { immediate: true, deep: true })

    async function checkInvoiceNumber(value) {
      const res = await apiInvoice.searchInvoice({
        invNumber: value,
      })
      return res.items.find(tk => String(tk.invNumber) === String(value))
    }
    const invalidInvoiceNumbers = ref([]) // những số hoá đơn để validate trùng ???

    // NOTE: auto get invoice by invoice number
    const onInvoiceNumberChange = debounce(async (value, invoiceItem) => {
      // nếu HĐ nhập tay vào mà đã có trong list thì ko tự động fill
      if (selectedInvoices.value
        .filter(item => item.id !== invoiceItem.id)
        .find(item => String(item.invoiceNumber) === String(value))
      ) return
      // call api tìm HĐ nhập tay, tự động fill thông tin
      const invoiceFound = await checkInvoiceNumber(value)
      if (invoiceFound) {
        await removeLineHandle(invoiceItem)
        store.dispatch(`${INCORRECT_INVOICE_STORE}/setSelectedInvoices`, {
          item: invoiceFound,
        })
        invalidInvoiceNumbers.value = invalidInvoiceNumbers.value.filter(i => i !== value)
      } else if (value) {
        invalidInvoiceNumbers.value.push(value)
      }
    }, 300)

    return {
      onlyUpperCaseFormatter,
      formatCurrency,
      convertISODateTime,
      tableColumnIncorrectInvoices,
      addLineHandle,
      removeLineHandle,
      selectedInvoices,
      clearAllLine,
      today: getToday('YYYY-MM-DD'),
      onInvoiceNumberChange,
      invalidInvoiceNumbers,
      isUniqueTicketNumber,
      minValue,
      TYPE_OF_INVOICE_APPLICATION_OPTIONS,
      INCORRECT_INVOICE_ACTION_OPTIONS,
      Vietnamese,
    }
  },
  methods: {
    withPopper(dropdownList, component, { width }) {
      const dropdownMaxWidth = width ?? '450px'
      dropdownList.style.maxWidth = dropdownMaxWidth
      dropdownList.style.zIndex = '10000'
      const popper = createPopper(component.$refs.toggle, dropdownList, {
        placement: 'bottom',
        modifiers: [],
      })
      return () => popper.destroy()
    },
  },
}
</script>
<style lang="scss" scoped>
.radio-custom,
::v-deep {
  .custom-radio .custom-control-label {
    margin-top: 0;
  }
}

.checkbox-custom ::v-deep {
  .custom-control-label {
    margin-top: 2px;
  }
}

.custom-v-select-type-of-invoice-application::v-deep,
.custom-v-select-incorrect-invoice-action ::v-deep {
  .form-group {
    &:not(.is-invalid) {
      .v-select {
        .vs__dropdown-toggle {
          border: 0;
        }
      }
    }
  }
  .v-select {
    .vs__dropdown-toggle {
      box-shadow: none;
    }
  }
}
#incorrect-invoice-detail-card ::v-deep {
  .table.table-sm th, .table.table-sm td {
    padding: 0.3rem 0.2rem;
  }
  .form-control {
    padding: 0.3rem;
  }
  .width-120 {
    width: 120px;
  }
  .width-80 {
    width: 80px;
  }
  .width-100 {
    width: 100px;
  }
  .form-group {
    margin-bottom: 0.25rem;
  }
  .table {
    thead th {
      vertical-align: middle;
    }
  }
  .height-35px {
    height: 35px;
  }
}
</style>
