var render = function () {
  var _vm$invoiceTicketFlig, _vm$invoiceTicketFlig2;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BCard', {
    staticClass: "mb-0",
    attrs: {
      "body-class": "p-50"
    }
  }, [!_vm.isModal ? _c('div', {
    staticClass: "d-flex-between gap-1 bg-primary-gradient"
  }, [_c('div', {
    staticClass: "d-flex-center justify-content-start gap-1"
  }, [_c('IAmIcon', {
    staticClass: "p-1 icon-card",
    attrs: {
      "icon": "ticket",
      "size": "25"
    },
    nativeOn: {
      "dblclick": function dblclick($event) {
        $event.preventDefault();
        return _vm.onQuickChooseDateHandle($event);
      }
    }
  }), _c('span', {
    staticClass: "font-medium-3 m-0 fw-700"
  }, [_vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.title')) + " ")])], 1), _c('BButton', {
    staticClass: "p-50",
    attrs: {
      "variant": "flat-dark"
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('update:collapseSelectedTicket', !_vm.collapseSelectedTicket);
      }
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "resize",
      "size": "18"
    }
  })], 1)], 1) : _vm._e(), _c('BTabs', [_c('BTab', {
    attrs: {
      "title": _vm.$t('invoice.tabs.saleReport'),
      "active": "",
      "lazy": ""
    }
  }, [_c('BRow', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.from')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "DD/MM/YYYY",
      "disabled": !_vm.isEditing,
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: _vm.endDateFilter ? _vm.endDateFilter : _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.startDateFilter,
      callback: function callback($$v) {
        _vm.startDateFilter = $$v;
      },
      expression: "startDateFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.to')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "disabled": !_vm.isEditing,
      "placeholder": "DD/MM/YYYY",
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: _vm.startDateFilter,
        maxDate: _vm.today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.endDateFilter,
      callback: function callback($$v) {
        _vm.endDateFilter = $$v;
      },
      expression: "endDateFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.ticketNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('invoice.create.selectTicket.phTicketNumber'),
      "disabled": !_vm.isEditing,
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.ticketNumberFilter,
      callback: function callback($$v) {
        _vm.ticketNumberFilter = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "ticketNumberFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.ticketType')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.saleReportTicketTypeOptions,
      "disabled": !_vm.isEditing,
      "label": "label",
      "clearable": true,
      "placeholder": _vm.$t('invoice.create.selectTicket.phTicketType'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t(data.label)) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.ticketTypeFilter,
      callback: function callback($$v) {
        _vm.ticketTypeFilter = $$v;
      },
      expression: "ticketTypeFilter"
    }
  })], 1)], 1)], 1), _c('BRow', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.airline')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('saleReport.phAirline'),
      "formatter": _vm.trimUpperCaseInput,
      "disabled": !_vm.isEditing,
      "maxlength": "2"
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.airlineFilter,
      callback: function callback($$v) {
        _vm.airlineFilter = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "airlineFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.passengerName')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('invoice.create.selectTicket.passengerName'),
      "disabled": !_vm.isEditing,
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.paxNameFilter,
      callback: function callback($$v) {
        _vm.paxNameFilter = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "paxNameFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('invoice.create.selectTicket.phBookingCode'),
      "disabled": !_vm.isEditing,
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.bookingCodeFilter,
      callback: function callback($$v) {
        _vm.bookingCodeFilter = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "bookingCodeFilter"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "3"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.agencyCode')
    }
  }, [_c('vue-autosuggest', {
    attrs: {
      "id": "agency-code",
      "suggestions": _vm.agencySuggestions,
      "input-props": {
        placeholder: "".concat(_vm.$t('saleReport.phAgencyCode')),
        class: "form-control ".concat(_vm.agencyCodeFilter ? 'text-uppercase' : ''),
        disabled: !_vm.isEditing
      },
      "get-suggestion-value": _vm.getSuggestionValue
    },
    on: {
      "input": _vm.inputSuggestionHandle,
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var suggestion = _ref.suggestion;
        return [_c('span', {
          staticClass: "d-block font-weight-bold text-truncate text-uppercase"
        }, [_c('span', {
          staticClass: "text-info"
        }, [_vm._v(_vm._s(suggestion.item.agencyCode))]), _vm._v(" "), _c('small', [_vm._v("(" + _vm._s(suggestion.item.agencyName) + ")")])])];
      }
    }]),
    model: {
      value: _vm.agencyCodeFilter,
      callback: function callback($$v) {
        _vm.agencyCodeFilter = $$v;
      },
      expression: "agencyCodeFilter"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "my-25 d-flex-between gap-1"
  }, [_c('BButton', {
    attrs: {
      "variant": "info",
      "size": "sm",
      "disabled": !_vm.isEditing
    },
    on: {
      "click": _vm.refetchData
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "search",
      "size": "15",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.search')) + " ")], 1)], 1), _c('BButton', {
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": !_vm.isEditing
    },
    on: {
      "click": _vm.clearFilter
    }
  }, [_c('HStack', {
    attrs: {
      "center": ""
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash",
      "size": "15",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.clearFilter')) + " ")], 1)], 1)], 1), _c('div', {
    staticClass: "my-25"
  }, [_c('b-form-radio-group', {
    staticClass: "radio-custom",
    attrs: {
      "disabled": !_vm.isEditing,
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.flightTypeFilter,
      callback: function callback($$v) {
        _vm.flightTypeFilter = $$v;
      },
      expression: "flightTypeFilter"
    }
  }, [_vm._l(_vm.flightTypeOptions, function (item) {
    return _c('b-form-radio', {
      key: item.value,
      attrs: {
        "value": item.value
      }
    }, [_vm._v(" " + _vm._s(_vm.$t("invoice.create.selectTicket.".concat(item.value.toLowerCase(), "Ticket"))) + " ")]);
  }), !_vm.isHideTrain ? _c('b-form-radio', {
    attrs: {
      "value": "TRAIN"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("invoice.create.selectTicket.trainTicket")) + " ")]) : _vm._e()], 2), _c('HStack', {
    attrs: {
      "end": ""
    }
  }, [!_vm.isModal ? _c('BButton', {
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.isEditing,
      "size": "sm"
    },
    on: {
      "click": _vm.onClickExportSelectedTicketsHandle
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "cloudDownloadOutline",
      "size": "18",
      "color": "#2FA4E5"
    }
  }), _c('span', [_vm._v(_vm._s(_vm.$t('invoice.create.selectTicket.download')))])], 1)]) : _vm._e(), !_vm.isModal ? _c('BButton', {
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.isEditing,
      "size": "sm"
    },
    on: {
      "click": _vm.viewDetailsHandle
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('span', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.selectTicket.viewDetails')))]), _c('IAmIcon', {
    attrs: {
      "icon": "hyperlink",
      "size": "16",
      "color": "#2FA4E5"
    }
  })], 1)]) : _vm._e()], 1)], 1), _c('b-table', {
    ref: "refSaleReportListTableSelectTicket",
    staticClass: "position-relative mb-0",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": _vm.fetchSaleReports,
      "select-mode": "multi",
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "borderless": "",
      "hover": "",
      "fields": _vm.tableColumnSelectTicket,
      "busy": _vm.loadingSelectTicket,
      "tbody-tr-class": _vm.resolveColorRow
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnSelectTicket, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.create.selectTicket.columns.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref2) {
        var item = _ref2.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.isTicketSelectedCheck(item),
            "disabled": !_vm.isEditing || _vm.isDuplicate && item.isInvoiced
          },
          on: {
            "change": function change($event) {
              return _vm.onSelectRow(item);
            }
          }
        })];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref3) {
        var item = _ref3.item;
        return [_c('div', {
          staticClass: "font-weight-bolder"
        }, [_vm._v(" " + _vm._s(item.bookingCode) + " ")])];
      }
    }, {
      key: "cell(paxName)",
      fn: function fn(_ref4) {
        var item = _ref4.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.paxName) + " ")])];
      }
    }, {
      key: "cell(ticketNumber)",
      fn: function fn(_ref5) {
        var item = _ref5.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(item.ticketNumber) + " ")])];
      }
    }, {
      key: "cell(trips)",
      fn: function fn(_ref6) {
        var trips = _ref6.item.trips;
        return [trips ? _c('div', _vm._l(_vm.convertShortenTrips(trips), function (trip, tripIndex) {
          return _c('div', {
            key: tripIndex
          }, [_vm._v(" " + _vm._s(trip) + " ")]);
        }), 0) : _vm._e()];
      }
    }, {
      key: "cell(agencyCode)",
      fn: function fn(_ref7) {
        var _item$agency;
        var item = _ref7.item;
        return [_c('div', {
          staticClass: "text-nowrap text-uppercase"
        }, [_vm._v(" " + _vm._s((item === null || item === void 0 ? void 0 : (_item$agency = item.agency) === null || _item$agency === void 0 ? void 0 : _item$agency.agencyCode) || '') + " ")])];
      }
    }, {
      key: "cell(receivables)",
      fn: function fn(_ref8) {
        var item = _ref8.item;
        return [_c('div', {
          staticClass: "text-nowrap text-right fw-600"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency((item === null || item === void 0 ? void 0 : item.receivables) || 0)) + " ")])];
      }
    }, {
      key: "cell(flightDates)",
      fn: function fn(_ref9) {
        var flightDates = _ref9.item.flightDates;
        return [_vm._v(" " + _vm._s(_vm.replaceText(flightDates)) + " ")];
      }
    }, {
      key: "cell(fareBasisCode)",
      fn: function fn(_ref10) {
        var fareBasisCode = _ref10.item.fareBasisCode;
        return [_vm._v(" " + _vm._s(_vm.replaceText(fareBasisCode)) + " ")];
      }
    }, {
      key: "cell(bookingDate)",
      fn: function fn(_ref11) {
        var item = _ref11.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.replaceText(_vm.convertISODateTime(item.bookingDate).dateTime)) + " ")])];
      }
    }, {
      key: "cell(ticketType)",
      fn: function fn(_ref12) {
        var item = _ref12.item;
        return [_c('div', [_vm._v(" " + _vm._s(_vm.$te("saleReport.".concat(item.ticketType)) ? _vm.$t("saleReport.".concat(item.ticketType)) : item.ticketType) + " ")])];
      }
    }, {
      key: "cell(balanceAdjustment)",
      fn: function fn(_ref13) {
        var balanceAdjustment = _ref13.item.balanceAdjustment;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(balanceAdjustment)) + " ")])];
      }
    }, {
      key: "cell(priceTicket)",
      fn: function fn(_ref14) {
        var priceTicket = _ref14.item.priceTicket;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(priceTicket)) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref15) {
        var collectionFee = _ref15.item.collectionFee;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(collectionFee)) + " ")])];
      }
    }, {
      key: "cell(tax)",
      fn: function fn(_ref16) {
        var item = _ref16.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.tax)) + " ")])];
      }
    }, {
      key: "cell(otherTax)",
      fn: function fn(_ref17) {
        var item = _ref17.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.otherTax)) + " ")])];
      }
    }, {
      key: "cell(fee)",
      fn: function fn(_ref18) {
        var item = _ref18.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.fee)) + " ")])];
      }
    }, {
      key: "cell(feeIn)",
      fn: function fn(_ref19) {
        var item = _ref19.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.isRoleF2 ? item.feeOut : item.feeIn)) + " ")])];
      }
    }, {
      key: "cell(feeOut)",
      fn: function fn(_ref20) {
        var item = _ref20.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.isRoleF2 ? item.feeIn : item.feeOut)) + " ")])];
      }
    }, {
      key: "cell(feeService)",
      fn: function fn(_ref21) {
        var item = _ref21.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.feeService)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaid)",
      fn: function fn(_ref22) {
        var item = _ref22.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.commissionPrepaid)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaidIn)",
      fn: function fn(_ref23) {
        var item = _ref23.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.commissionPrepaidIn)) + " ")])];
      }
    }, {
      key: "cell(commissionPrepaidOut)",
      fn: function fn(_ref24) {
        var item = _ref24.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.commissionPrepaidOut)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommission)",
      fn: function fn(_ref25) {
        var item = _ref25.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unpaidCommission)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommissionIn)",
      fn: function fn(_ref26) {
        var item = _ref26.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unpaidCommissionIn)) + " ")])];
      }
    }, {
      key: "cell(unpaidCommissionOut)",
      fn: function fn(_ref27) {
        var item = _ref27.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unpaidCommissionOut)) + " ")])];
      }
    }, {
      key: "cell(commission)",
      fn: function fn(_ref28) {
        var item = _ref28.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.commission)) + " ")])];
      }
    }, {
      key: "cell(tradeCreditors)",
      fn: function fn(_ref29) {
        var item = _ref29.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.tradeCreditors)) + " ")])];
      }
    }, {
      key: "cell(profit)",
      fn: function fn(_ref30) {
        var item = _ref30.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.profit)) + " ")])];
      }
    }, {
      key: "cell(totalPayment)",
      fn: function fn(_ref31) {
        var totalPayment = _ref31.item.totalPayment;
        return [_c('div', {
          staticClass: "font-weight-bold text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(totalPayment)) + " ")])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.replaceText(_vm.dateTime(data.item.createdAt))) + " ")])];
      }
    }, {
      key: "cell(updatedAt)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0"
        }, [_vm._v(" " + _vm._s(_vm.replaceText(_vm.dateTime(data.item.updatedAt))) + " ")])];
      }
    }, {
      key: "cell(agency)",
      fn: function fn(data) {
        return [_c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.agency.agencyCode.toUpperCase()) + " ")])];
      }
    }, {
      key: "cell(booker)",
      fn: function fn(data) {
        return [data.item.booker ? _c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.booker.username.toUpperCase()) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(issuer)",
      fn: function fn(data) {
        return [data.item.issuer ? _c('div', {
          staticClass: "mb-0 text-nowrap"
        }, [_vm._v(" " + _vm._s(data.item.issuer.username.toUpperCase()) + " ")]) : _vm._e()];
      }
    }, {
      key: "cell(issueDate)",
      fn: function fn(_ref32) {
        var issueDate = _ref32.item.issueDate;
        return [_vm._v(" " + _vm._s(_vm.replaceText(_vm.convertISODateTime(issueDate).dateTime)) + " ")];
      }
    }, {
      key: "bottom-row",
      fn: function fn() {
        return _vm._l(['checkbox', 'receivables'], function (field, i) {
          return _c('td', {
            key: i,
            class: "text-18px fw-700 ".concat(field === 'receivables' ? 'position-sticky position-right-0 text-right' : field === 'checkbox' ? 'position-sticky position-left-0 text-left' : ''),
            attrs: {
              "colspan": field === 'receivables' ? 3 : field === 'checkbox' ? 2 : 0
            }
          }, [field === 'receivables' ? _c('div', [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.totalOriginAmountSelectedTicket)) + " ")]) : _vm._e(), field === 'checkbox' ? _c('div', {
            staticClass: "text-uppercase"
          }, [_vm._v(" " + _vm._s(_vm.$t('saleReport.sum')) + " ")]) : _vm._e()]);
        });
      },
      proxy: true
    }], null, true)
  })], 1), _c('BTab', {
    attrs: {
      "title": _vm.$t('invoice.tabs.invoiceTickets'),
      "lazy": ""
    }
  }, [_c('BRow', {
    staticClass: "px-75"
  }, [_c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.from')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "placeholder": "DD/MM/YYYY",
      "disabled": !_vm.isEditing,
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        maxDate: (_vm$invoiceTicketFlig = _vm.invoiceTicketFlightDateTo) !== null && _vm$invoiceTicketFlig !== void 0 ? _vm$invoiceTicketFlig : null,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.invoiceTicketFlightDateFrom,
      callback: function callback($$v) {
        _vm.invoiceTicketFlightDateFrom = $$v;
      },
      expression: "invoiceTicketFlightDateFrom"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0",
      "label": _vm.$t('invoice.filters.to')
    }
  }, [_c('flat-pickr', {
    staticClass: "form-control",
    attrs: {
      "disabled": !_vm.isEditing,
      "placeholder": "DD/MM/YYYY",
      "config": {
        allowInput: true,
        dateFormat: 'Y-m-d',
        altFormat: 'd/m/Y',
        altInput: true,
        minDate: (_vm$invoiceTicketFlig2 = _vm.invoiceTicketFlightDateFrom) !== null && _vm$invoiceTicketFlig2 !== void 0 ? _vm$invoiceTicketFlig2 : null,
        // maxDate: today,
        locale: this.$i18n.locale === 'vi' ? _vm.Vietnamese : null,
        disableMobile: true
      }
    },
    model: {
      value: _vm.invoiceTicketFlightDateTo,
      callback: function callback($$v) {
        _vm.invoiceTicketFlightDateTo = $$v;
      },
      expression: "invoiceTicketFlightDateTo"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.ticketType')
    }
  }, [_c('v-select', {
    staticClass: "w-100 vue-select-class",
    staticStyle: {
      "font-size": "1rem"
    },
    attrs: {
      "options": _vm.invoiceTicketTypeOptions,
      "disabled": !_vm.isEditing,
      "label": "label",
      "clearable": true,
      "placeholder": _vm.$t('invoice.create.selectTicket.phTicketType'),
      "reduce": function reduce(val) {
        return val.value;
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(data.label))) + " ")];
      }
    }, {
      key: "selected-option",
      fn: function fn(data) {
        return [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(data.label))) + " ")];
      }
    }, {
      key: "no-options",
      fn: function fn() {
        return [_vm._v(" " + _vm._s(_vm.$t('noOptions')) + " ")];
      },
      proxy: true
    }]),
    model: {
      value: _vm.invoiceTicketType,
      callback: function callback($$v) {
        _vm.invoiceTicketType = $$v;
      },
      expression: "invoiceTicketType"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.create.selectTicket.ticketNumber')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('invoice.create.selectTicket.phTicketNumber'),
      "disabled": !_vm.isEditing,
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.invoiceTicketTicketNumber,
      callback: function callback($$v) {
        _vm.invoiceTicketTicketNumber = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "invoiceTicketTicketNumber"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.buyer.name')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('invoice.buyer.name'),
      "disabled": !_vm.isEditing,
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.invoiceTicketCustomerName,
      callback: function callback($$v) {
        _vm.invoiceTicketCustomerName = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "invoiceTicketCustomerName"
    }
  })], 1)], 1), _c('b-col', {
    staticClass: "px-25",
    attrs: {
      "cols": "12",
      "md": "4"
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0",
    attrs: {
      "label-class": "h5 py-0 fw-600",
      "label": _vm.$t('invoice.invoiceTickets.fields.bookingCode')
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('invoice.ph.bookingCode'),
      "disabled": !_vm.isEditing,
      "formatter": _vm.onlyUpperCaseFormatter
    },
    on: {
      "keyup": function keyup($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) { return null; }
        return _vm.refetchData($event);
      }
    },
    model: {
      value: _vm.invoiceTicketBookingCode,
      callback: function callback($$v) {
        _vm.invoiceTicketBookingCode = typeof $$v === 'string' ? $$v.trim() : $$v;
      },
      expression: "invoiceTicketBookingCode"
    }
  })], 1)], 1)], 1), _c('div', {
    staticClass: "my-25 d-flex-between gap-1"
  }, [_c('BButton', {
    staticClass: "d-flex-center gap-1",
    attrs: {
      "variant": "info",
      "size": "sm",
      "disabled": !_vm.isEditing
    },
    on: {
      "click": _vm.invoiceTicketRefetchData
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "search",
      "size": "15",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.search')) + " ")], 1), _c('BButton', {
    staticClass: "d-flex-center gap-1",
    attrs: {
      "variant": "danger",
      "size": "sm",
      "disabled": !_vm.isEditing
    },
    on: {
      "click": _vm.invoiceTicketClearFilter
    }
  }, [_c('IAmIcon', {
    attrs: {
      "icon": "trash",
      "size": "15",
      "color": "white"
    }
  }), _vm._v(" " + _vm._s(_vm.$t('invoice.create.selectTicket.clearFilter')) + " ")], 1)], 1), _c('div', {
    staticClass: "d-flex-center justify-content-end my-25"
  }, [!_vm.isModal ? _c('BButton', {
    attrs: {
      "variant": "flat-info",
      "disabled": !_vm.isEditing,
      "size": "sm"
    },
    on: {
      "click": _vm.viewDetailsHandle
    }
  }, [_c('div', {
    staticClass: "d-flex-center gap-1"
  }, [_c('span', {
    staticStyle: {
      "text-decoration": "underline"
    }
  }, [_vm._v(_vm._s(_vm.$t('invoice.create.selectTicket.viewDetails')))]), _c('IAmIcon', {
    attrs: {
      "icon": "hyperlink",
      "size": "16",
      "color": "#2FA4E5"
    }
  })], 1)]) : _vm._e()], 1), _c('b-table', {
    ref: "refInvoiceTicketListTable",
    staticClass: "position-relative mb-0",
    staticStyle: {
      "max-height": "70vh"
    },
    attrs: {
      "sticky-header": true,
      "items": function items(ctx, callback) {
        var _vm$invoiceData$id, _vm$invoiceData;
        return _vm.getInvoiceTickets(ctx, callback, true, (_vm$invoiceData$id = (_vm$invoiceData = _vm.invoiceData) === null || _vm$invoiceData === void 0 ? void 0 : _vm$invoiceData.id) !== null && _vm$invoiceData$id !== void 0 ? _vm$invoiceData$id : null);
      },
      "select-mode": "multi",
      "responsive": "",
      "primary-key": "id",
      "show-empty": "",
      "empty-text": _vm.$t('noMatchingResult'),
      "no-border-collapse": "",
      "small": "",
      "borderless": "",
      "hover": "",
      "fields": _vm.tableColumnsInvoiceTicket,
      "busy": _vm.loadingSelectTicket,
      "tbody-tr-class": _vm.resolveColorRow
    },
    scopedSlots: _vm._u([_vm._l(_vm.tableColumnsInvoiceTicket, function (column) {
      return {
        key: "head(".concat(column.key, ")"),
        fn: function fn(data) {
          return [_c('span', {
            key: column.key,
            staticClass: "text-dark text-nowrap"
          }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.fields.".concat(data.column))) + " ")])];
        }
      };
    }), {
      key: "table-busy",
      fn: function fn() {
        return [_c('div', {
          staticClass: "d-flex-center text-dark my-2 gap-2"
        }, [_c('b-spinner', {
          staticClass: "align-middle"
        }), _c('strong', [_vm._v(_vm._s(_vm.$t('loading')))])], 1)];
      },
      proxy: true
    }, {
      key: "cell(checkbox)",
      fn: function fn(_ref33) {
        var item = _ref33.item;
        return [_c('b-form-checkbox', {
          attrs: {
            "checked": _vm.isTicketSelectedCheck(item),
            "disabled": !_vm.isEditing || _vm.isDuplicate && item.isInvoiced
          },
          on: {
            "change": function change($event) {
              return _vm.onSelectRow(item);
            }
          }
        })];
      }
    }, {
      key: "cell(customerName)",
      fn: function fn(_ref34) {
        var item = _ref34.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [item.customerName ? _c('span', [_vm._v(" " + _vm._s(item.customerName) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(ticketNumber)",
      fn: function fn(_ref35) {
        var item = _ref35.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [item.ticketNumber ? _c('span', [_vm._v(" " + _vm._s(item.ticketNumber) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(bookingCode)",
      fn: function fn(_ref36) {
        var item = _ref36.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [item.bookingCode ? _c('span', [_vm._v(" " + _vm._s(item.bookingCode) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(flightTrip)",
      fn: function fn(_ref37) {
        var item = _ref37.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [item.flightTrip ? _c('span', [_vm._v(" " + _vm._s(item.flightTrip) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(type)",
      fn: function fn(_ref38) {
        var item = _ref38.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.invoiceTickets.type.".concat(item.type))) + " ")])];
      }
    }, {
      key: "cell(flightDate)",
      fn: function fn(_ref39) {
        var item = _ref39.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [item.flightDate ? _c('span', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.flightDate).date) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(createdAt)",
      fn: function fn(_ref40) {
        var item = _ref40.item;
        return [_c('div', {
          staticClass: "text-nowrap"
        }, [item.createdAt ? _c('span', [_vm._v(" " + _vm._s(_vm.convertISODateTime(item.createdAt).date) + " ")]) : _c('span', {
          staticClass: "text-muted font-italic"
        }, [_vm._v(" " + _vm._s("<".concat(_vm.$t('empty'), ">")) + " ")])])];
      }
    }, {
      key: "cell(totalAmount)",
      fn: function fn(_ref41) {
        var item = _ref41.item;
        return [_c('div', {
          staticClass: "text-right"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.unitPrice)) + " ")])];
      }
    }, {
      key: "cell(collectionFee)",
      fn: function fn(_ref42) {
        var item = _ref42.item;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.formatCurrency(item.collectionFee)) + " ")])];
      }
    }, {
      key: "cell(valueAddedTax)",
      fn: function fn(_ref43) {
        var item = _ref43.item;
        return [_c('div', {
          staticClass: "text-right text-nowrap"
        }, [_vm._v(" " + _vm._s(_vm.$t("invoice.taxTypes.".concat(item.valueAddedTax))) + " ")])];
      }
    }], null, true)
  })], 1)], 1), _c('ModalSelectTicket', {
    attrs: {
      "is-modal": true
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }