import {
  ref, computed, watch, getCurrentInstance,
} from '@vue/composition-api'
import { isNull } from 'lodash-es'

import {
  INVOICE_APP_STORE_MODULE_NAME as INVOICE_STORE, INVOICE_LIST_COLUMNS, INVOICE_LIST_FIXED_COLUMNS, GOODS_SERVICE_TYPES,
  CREATED_TYPE,
  INVOICE_ADDITIONAL_LIST_PATH_NAME,
  INVOICE_REQUEST_LIST_PATH_NAME,
  INVOICE_REQUEST_STATUS_OPTIONS,
  REQUEST_RELEASE_STATUS,
} from '@/constants/invoice'
import store from '@/store'
import { useRouter } from '@/@core/utils/utils'
import { convertISODateTime } from '@/@core/utils/filter'

import useToast from '@useToast'

import { resolveRequestReleaseInvoiceStatus } from '../useInvoiceHandle'

export default function useInvoiceListHandle() {
  const { route } = useRouter()
  const { toastError, toastSuccess } = useToast()
  const vm = getCurrentInstance().proxy

  const refInvoiceListTable = ref(null)
  const loading = ref(false)

  const onlyTotalAmountAdditional = computed(() => route.value.name === INVOICE_ADDITIONAL_LIST_PATH_NAME) // Danh sách hoá đơn xuất chênh
  const isInvoiceRequest = computed(() => route.value.name === INVOICE_REQUEST_LIST_PATH_NAME) // Danh sách gửi yêu cầu xuất hoá đơn

  const tableColumnInvoiceList = computed(() => {
    const columnsInStore = store.getters[`${INVOICE_STORE}/getColumnsList`]
    return INVOICE_LIST_COLUMNS
      .filter(col => {
        if (INVOICE_LIST_FIXED_COLUMNS.includes(col.key)) return true
        if (onlyTotalAmountAdditional.value) return col.defaultForAdditional
        if (isInvoiceRequest.value && ['releaseStatus', 'signStatus', 'invoiceStatus', 'sendMailStatus'].includes(col.key)) return false
        if (isInvoiceRequest.value && col.defaultForRequest) return true
        return columnsInStore.includes(col.key)
      })
  })
  async function onApplySelectColumnsHandle(columns) {
    await store.dispatch('app-invoice/setColumnsList', columns)
  }

  const invoicesList = ref([])
  // filter
  const startDate = ref('') // từ ngày
  const endDate = ref('') // đến ngày
  const invoiceStatus = ref(null) // trạng thái hoá đơn
  const releaseStatus = ref(null) // trạng thái phát hành
  const sendMailStatus = ref('') // trạng thái gửi mail
  const additionalAmountStatus = ref('') // trạng thái phí xuất chênh
  const signStatus = ref('') // trạng thái gửi mail
  const agencyCode = ref('') // mã đại lý (theo đại lý nợ)
  const invNumber = ref('') // số hoá đơn
  const tagsFilter = ref([]) // nhãn
  const referenceCode = ref('') // mã tra cứu
  const buyerName = ref('') // tên khách hàng
  const createdTypeFilter = ref(route.value.query?.createdType) // loại yêu cầu xuất hoá đơn/ // REQUEST / RELEASE
  const requestStatusFilter = ref() // trạng thái yêu cầu
  // const searchText = ref('') // text

  const sizePerPage = ref(10)
  const currentPage = ref(1)
  const sortBy = ref(null) // createdAt || updatedAt
  const isSortDirDesc = ref(true) //  ASC || DESC

  const totalInvoices = ref(0)

  const dataMeta = computed(() => {
    const localItemsCount = refInvoiceListTable.value ? refInvoiceListTable.value.localItems.length : 0
    return {
      from: sizePerPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: sizePerPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalInvoices.value,
    }
  })

  const clearFilter = () => {
    startDate.value = ''
    endDate.value = ''
    invoiceStatus.value = null
    releaseStatus.value = null
    sendMailStatus.value = ''
    additionalAmountStatus.value = ''
    tagsFilter.value = []
    signStatus.value = ''
    agencyCode.value = ''
    invNumber.value = ''
    referenceCode.value = ''
    buyerName.value = ''
    requestStatusFilter.value = ''
    // searchText.value = ''
  }

  const refetchData = () => {
    refInvoiceListTable.value.refresh()
  }

  // logic filter for createdType
  function mappingCreatedTypeAdditionFilters() {
    let invoiceStatusScope = invoiceStatus.value ?? undefined
    let releaseStatusScope = releaseStatus.value ?? undefined
    let requestReleaseStatus
    if (isInvoiceRequest.value) {
      if (createdTypeFilter.value !== CREATED_TYPE.REQUEST) {
        createdTypeFilter.value = CREATED_TYPE.RELEASE
        if (requestStatusFilter.value) {
          const requestStatusFound = INVOICE_REQUEST_STATUS_OPTIONS
            .find(statusItem => statusItem.value === requestStatusFilter.value)
          invoiceStatusScope = requestStatusFound?.invoiceStatus
          releaseStatusScope = requestStatusFound?.releaseStatus
          requestReleaseStatus = isNull(requestStatusFound?.requestReleaseStatus)
            ? REQUEST_RELEASE_STATUS.MIGHT_SENT
            : (requestStatusFound?.requestReleaseStatus ?? undefined)
        }
      }
    }
    else {
      createdTypeFilter.value = CREATED_TYPE.INVOICE
      requestStatusFilter.value = undefined
    }
    return {
      screenType: createdTypeFilter.value, // filter theo màn hình
      requestReleaseStatus,
      invoiceStatus: invoiceStatusScope,
      releaseStatus: releaseStatusScope,
    }
  }

  const nonePagination = ref(false)
  const isIncorrectNotified = ref(null) // Hoá đơn đã được gửi TBSS hay chưa
  const fetchInvoices = (_ctx, callback) => {
    loading.value = true
    const params = {
      startDate: startDate.value || undefined,
      endDate: endDate.value || undefined,
      invoiceStatus: invoiceStatus.value ?? undefined,
      releaseStatus: releaseStatus.value ?? undefined,
      sendMailStatus: sendMailStatus.value || undefined,
      tags: tagsFilter.value.length ? tagsFilter.value : undefined,
      signStatus: signStatus.value || undefined,
      invNumber: invNumber.value || undefined,
      referenceCode: referenceCode.value || undefined,
      buyerName: buyerName.value || undefined,
      agencyReceiveCode: agencyCode.value || undefined,
      sortBy: sortBy.value ? sortBy.value : 'createdAt',
      sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
      size: sizePerPage.value,
      page: currentPage.value,
      // searchText: searchText.value || undefined,
      onlyTotalAmountAdditional: onlyTotalAmountAdditional.value || undefined,
      additionalAmountStatus: onlyTotalAmountAdditional.value ? (additionalAmountStatus.value || undefined) : undefined,
      nonePagination: nonePagination.value || undefined,
      isIncorrectNotified: isIncorrectNotified.value ?? undefined,
      ...mappingCreatedTypeAdditionFilters(),
    }

    store
      .dispatch('app-invoice/fetchInvoices', params)
      .then(res => {
        totalInvoices.value = res.count
        invoicesList.value = res.items.map(item => ({
          ...item,
          invoiceItems: (item?.invoiceItems || []).filter(item => item?.itemType === GOODS_SERVICE_TYPES.GOODS_SERVICE),
          requestStatus: resolveRequestReleaseInvoiceStatus(item), // trạng thái yêu cầu, FE tự define
        }))
        callback(invoicesList.value)
      })
      .catch(error => {
        console.error('error', error)
        toastError('Lỗi tải danh sách hoá đơn')
      })
      .finally(() => {
        loading.value = false
      })
  }

  watch(
    [currentPage, sizePerPage, sortBy, isSortDirDesc, onlyTotalAmountAdditional,
      isInvoiceRequest, createdTypeFilter, requestStatusFilter],
    () => {
      refetchData()
    },
  )

  const exportInvoices = (invoiceIds = []) => new Promise((resolve, reject) => {
    vm.$bvModal.show('modal-api-loading')
    const payload = invoiceIds.length
      ? { invoiceIds }
      : {
        startDate: startDate.value || undefined,
        endDate: endDate.value || undefined,
        invoiceStatus: invoiceStatus.value ?? undefined,
        releaseStatus: releaseStatus.value ?? undefined,
        sendMailStatus: sendMailStatus.value || undefined,
        tags: tagsFilter.value.length ? tagsFilter.value : undefined,
        signStatus: signStatus.value || undefined,
        invNumber: invNumber.value || undefined,
        referenceCode: referenceCode.value || undefined,
        buyerName: buyerName.value || undefined,
        agencyReceiveCode: agencyCode.value || undefined,
        sortBy: sortBy.value ? sortBy.value : 'createdAt',
        sortDirection: isSortDirDesc.value ? 'DESC' : 'ASC',
        size: sizePerPage.value,
        page: currentPage.value,
        onlyTotalAmountAdditional: onlyTotalAmountAdditional.value,
        additionalAmountStatus: onlyTotalAmountAdditional.value ? (additionalAmountStatus.value || undefined) : undefined,
        nonePagination: nonePagination.value || undefined,
        isIncorrectNotified: isIncorrectNotified.value ?? undefined,
        ...mappingCreatedTypeAdditionFilters(),
      }
    const fileName = `${onlyTotalAmountAdditional.value ? 'CongNoXuatChenhHoaDon' : isInvoiceRequest.value ? 'DanhSachYeuCauXuatHoaDon' : 'DanhSachHoaDon'}_${convertISODateTime(new Date()).date.replaceAll('/', '')}`
    store
      .dispatch('app-invoice/exportInvoices', { payload, fileName })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.exportSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.exportError',
        })
        reject(e)
      })
      .finally(() => {
        vm.$bvModal.hide('modal-api-loading')
      })
  })

  const updateAdditionalAmountStatus = (invoiceId, payload = {}) => new Promise((resolve, reject) => {
    store
      .dispatch('app-invoice/updateAdditionalAmountStatus', { id: invoiceId, payload })
      .then(res => {
        resolve(res)
        toastSuccess({
          title: 'messagesList.success',
          content: 'invoice.msg.updateInvoiceSuccess',
        })
      })
      .catch(e => {
        toastError({
          title: 'messagesList.error',
          content: 'invoice.msg.updateInvoiceError',
        })
        reject(e)
      })
  })

  return {
    // searchText,
    refInvoiceListTable,
    fetchInvoices,
    loading,
    refetchData,
    clearFilter,
    dataMeta,
    sizePerPage,
    currentPage,
    totalInvoices,
    onApplySelectColumnsHandle,

    // Filter
    isSortDirDesc,
    sortBy,
    startDate,
    endDate,
    invoiceStatus,
    releaseStatus,
    sendMailStatus,
    tagsFilter,
    signStatus,
    agencyCode,
    buyerName,
    referenceCode,
    invNumber,
    nonePagination,
    onlyTotalAmountAdditional,
    additionalAmountStatus,
    updateAdditionalAmountStatus,

    // constants
    INVOICE_STORE,
    tableColumnInvoiceList,

    exportInvoices,
    isInvoiceRequest,
    createdTypeFilter,
    requestStatusFilter,
    isIncorrectNotified,
  }
}
