import { cloneDeep } from 'lodash-es'

import { apiInvoice } from '@/api'
import { convertISODateTime } from '@/@core/utils/filter'
import { numberToFixed } from '@/@core/utils/numberUtils'

import { TYPE_OF_INVOICE_APPLICATION } from './useIncorrectInvoiceHandle'

export default {
  namespaced: true,
  state: {
    selectedInvoices: [], // invoices incorrect items
  },
  getters: {
    getSelectedInvoices: state => state.selectedInvoices,
    findSelectedInvoice: state => invoice => state.selectedInvoices
      .find(item => item.invoiceNumber === invoice.invNumber || item.id === invoice.id),
  },
  mutations: {
    SET_SELECTED_INVOICES(state, value) {
      state.selectedInvoices = value
    },
    CLEAR_SELECTED_INVOICES(state) {
      state.selectedInvoices = []
    },
  },
  actions: {
    // ANCHOR List
    async fetchIncorrectInvoices(_ctx, payload) {
      const res = await apiInvoice.searchIncorrectInvoices(payload)
      return res
    },

    createIncorrectInvoice(_ctx, payload) {
      return apiInvoice.createIncorrectInvoice(payload)
    },

    getIncorrectInvoiceById(_ctx, incorrectInvoiceId) {
      return apiInvoice.getIncorrectInvoiceById(incorrectInvoiceId)
    },

    // ANCHOR: Select card
    setSelectedInvoicesInDetail({ commit }, invoices) {
      commit('SET_SELECTED_INVOICES', invoices)
    },
    setSelectedInvoices({ getters, dispatch }, { item: invItem }) {
      const findInvoice = getters.findSelectedInvoice(invItem)
      let selectedInvoices = cloneDeep(getters.getSelectedInvoices)
      if (findInvoice) {
        selectedInvoices = selectedInvoices.filter(item => item.invoiceNumber !== invItem.invNumber)
      } else {
        const mappingItem = {
          id: invItem?.externalId,
          typeOfInvoiceApplication: TYPE_OF_INVOICE_APPLICATION.TYPE_1, // Loại HĐ Điện tử áp dụng
          taxAuthorityCode: invItem?.releasedInvCode ?? '',
          templateNumberAndSymbol: `${invItem?.templateNumber}${invItem?.symbol}`,
          invoiceNumber: invItem?.invNumber,
          invoiceDate: convertISODateTime(invItem?.createdAt).dateFilter,
          action: null,
          reason: '',
          amount: numberToFixed(invItem?.totalAmount),
          isInternal: true,
          incorrectInvoiceNotificationId: null,
          invoiceEmail: invItem?.buyerEmail,
          invoiceBuyerName: invItem?.buyerName,
          invoiceTotalAmount: numberToFixed(invItem?.totalAmount),
          invoiceBuyerUnitName: invItem?.buyerUnitName,
        }

        const findLastIndexOfItem = selectedInvoices
          .findLastIndex(item => item.flightType === mappingItem.flightType)

        if (findLastIndexOfItem >= 0) {
          selectedInvoices.splice(findLastIndexOfItem + 1, 0, mappingItem)
        } else {
          selectedInvoices.push(mappingItem)
        }
      }
      dispatch('setSelectedInvoicesInDetail', selectedInvoices)
      // dispatch('setCollectionFee', selectedInvoices.reduce((acc, ticket) => acc + (ticket.collectionFee ?? 0), 0))
      // dispatch('setTotalGoodsAmount', selectedInvoices.reduce((acc, ticket) => acc + (ticket.amount ?? 0), 0))
      // dispatch('setNeedToPayAmount', 0)
    },

    addSelectedInvoice({ dispatch, getters }, invoiceItem) {
      const selectedInvoices = cloneDeep(getters.getSelectedInvoices)
      selectedInvoices.push(invoiceItem)
      dispatch('setSelectedInvoicesInDetail', selectedInvoices)
    },

    removeSelectedInvoice({ dispatch, getters }, invoiceItem) {
      let selectedInvoices = cloneDeep(getters.getSelectedInvoices)
      selectedInvoices = selectedInvoices.filter(ticket => ticket.id !== invoiceItem.id)
      dispatch('setSelectedInvoicesInDetail', selectedInvoices)
    },

    clearSelectedInvoices({ commit }) {
      commit('CLEAR_SELECTED_INVOICES')
    },

    // ANCHOR DETAIL
    updateIncorrectInvoice(_ctx, { id: incorrectInvoiceId, payload }) {
      return apiInvoice.updateIncorrectInvoice(incorrectInvoiceId, payload)
    },

    // ANCHOR PREVIEW PDF
    getIncorrectInvoicePdfById(_ctx, id) {
      return apiInvoice.getIncorrectInvoicePdfById(id)
    },

    // ANCHOR sign and release
    signIncorrectInvoices(_ctx, payload) {
      return apiInvoice.signIncorrectInvoices(payload)
    },
    releaseIncorrectInvoices(_ctx, payload) {
      return apiInvoice.releaseIncorrectInvoices(payload)
    },
    getIncorrectInvoicesXml(_ctx, payload) {
      return apiInvoice.getIncorrectInvoicesXml(payload)
    },
    signCompleteIncorrectInvoices(_ctx, payload) {
      return apiInvoice.signCompleteIncorrectInvoices(payload)
    },
    // ANCHOR Send emails
    sendIncorrectInvoiceEmails(_ctx, payload) {
      return apiInvoice.sendIncorrectInvoiceEmails(payload)
    },
    // ANCHOR Export
    exportIncorrectInvoice(_ctx, { payload, fileName }) {
      return apiInvoice.exportIncorrectInvoices(payload, fileName)
    },
  },
}
